import React from "react";
import styled from "styled-components";

const Header = styled.div`
  height: 90px;
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 24px;
  color: ${(props) => props.theme.black};
`;

const SubTitle = styled.div`
  margin-top: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.darkGrey};
`;

const titleFactory = (page) => {
  switch (page) {
    case "userList":
      return { title: "유저 목록", subTitle: "유저를 관리합니다." };
    case "sendLog":
      return { title: "전송 로그", subTitle: "유저간 토큰전송 혹은 ETH의 전송 기록입니다." };
    case "lockupList":
      return {
        title: "락업 로그",
        subTitle: "락업 관련 로그를 조회합니다.",
      };
    case "stakingList":
      return { title: "스테이킹 로그", subTitle: "스테이킹 관련 로그를 조회합니다." };
    case "noticeList":
      return { title: "공지사항 관리", subTitle: "공지사항을 관리합니다." };
    case "noticeWrite":
      return { title: "공지사항 작성", subTitle: "공지사항을 작성합니다.." };
    case "noticeUpdate":
      return { title: "공지사항 수정", subTitle: "공지사항을 수정합니다." };
    case "swapList":
      return { title: "스왑 관리", subTitle: "에셋간 스왑을 관리 및 조회합니다." };
    case "balance":
      return { title: "유저 잔액 조회", subTitle: "유저 에셋간 잔액을 조회합니다." };
    case "shopExchange":
      return { title: "(가맹점전용)원화 환전 조회", subTitle: "가맹점에서 신청한 원화 환전 기록을 관리합니다." };
    case "withdraw":
      return {
        title: "출금신청",
        subTitle: "유저가 송금할 때 플랫폼 내부의 지갑주소가 아닌 외부의 지갑주소로 보낼 시엔 출금신청됩니다.",
      };
    case "config":
      return { title: "설정", subTitle: "설정값을 변경합니다." };
    case "shopList":
      return { title: "가맹점 관리", subTitle: "가맹점을 관리합니다." };
  }
};

const AdminHeader = ({ page }) => {
  const info = titleFactory(page);

  return (
    <Header>
      <Title>{info.title}</Title>
      <SubTitle>{info.subTitle}</SubTitle>
    </Header>
  );
};

export default AdminHeader;
