import React from "react";
import Theme from "./Theme";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    color: Theme.black,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: Theme.mainColor,
    borderColor: "grey",
    color: Theme.black,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: Theme.lightGrey,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted lightGrey ",
    padding: 20,
    backgroundColor: "white",
    color: Theme.black,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 46,
    backgroundColor: "white",
  }),
  singleValue: (provided, state) => {
    return { ...provided, color: "black" };
  },
};

export default customStyles;
