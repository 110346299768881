import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Alert } from "reactstrap";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-slider/assets/index.css";
import Popup from "reactjs-popup";

import TokenRow from "../../Components/Main/TokenRow";
import DefaultButton from "../../Components/Common/DefaultButton";
import globalContext from "../../Hooks/globalContext";
import useInput from "../../Hooks/useInput";
import {
  AMOUNT_REGEX,
  CONTRACT_ADDRESS,
  GAS_PRICE_API,
  HOST,
  TOKEN_INFO,
  VIEWPORT_MAX_WIDTH,
} from "../../Utils/constants";
import Input from "../../Components/Common/SendInput";
import { languagePack } from "../../Utils/language";
import useState from "../../Hooks/useState";
import Axios from "axios";
import Loader from "../../Components/Common/Loader";
import Theme from "../../Styles/Theme";
import TokenSelect from "../../Components/Common/TokenSelect";
import PageWrapper from "../../Components/Common/PageWrapper";

const Container = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Form = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
`;

const QrForm = styled(Form)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  margin-bottom: 3px;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;

  font-size: 15px;
  color: ${(props) => props.theme.black};
  font-weight: bold;
  :hover {
    opacity: 0.75;
    text-decoration: underline;
  }
`;

const SliderForm = styled(Form)`
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    white-space: nowrap;
  }
`;

const FeeLabel = styled.div`
  width: 100%;
  color: grey;
  font-size: 15px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 15px;
`;

const NotificationWrapper = styled.div`
  width: 100%;
  padding: 0px 15px;
  font-size: 15px;
`;

const SideBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  margin: 12.5px 0px;
  padding: 0px 10px 0px 20px;
  color: ${(props) => props.theme.darkGrey};
`;

const PasswordTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const Handle = Slider.Handle;

const Page = ({ match }) => {
  const { user, language, balance, modal, sendEth, sendToken, web3, httpHeader, getBalance, contract } = useContext(
    globalContext
  );
  const amountInput = useInput({ defaultValue: "", regex: AMOUNT_REGEX });
  const toInput = useInput({ defaultValue: "", maxLength: 42 });
  const fee = useState(null);
  const selected = useState(
    match.params.asset
      ? match.params.asset.toLowerCase() === "gipk"
        ? "point"
        : match.params.asset.toLowerCase()
      : "eth"
  );
  const point = useState(null);
  const unavailable = useState(null);

  const passwordInput = useInput({ defaultValue: "" });

  const isLoading = useState(false);
  const gas = useState(null);
  const marks = useState({});

  const authOnClick = async (close) => {
    const { data } = await Axios.post(`${HOST}/auth/check`, { password: passwordInput.value }, httpHeader());

    if (data.status) {
      sendOnClick(close);
    } else {
      modal.setState("잘못된 비밀번호입니다.");
    }
  };

  const sendOnClick = async (close) => {
    if (toInput.value.length !== 42) {
      return modal.setState(languagePack("올바른 지갑주소가 아닙니다.", language.state));
    }
    if (!web3.utils.isAddress(toInput.value)) {
      return modal.setState(languagePack("올바른 지갑주소가 아닙니다.", language.state));
    }
    if (amountInput.value === "") {
      return modal.setState(languagePack("보낼 금액을 입력하세요.", language.state));
    }
    if (Number(amountInput.value) === 0) {
      return modal.setState(languagePack("보낼 금액을 입력하세요.", language.state));
    }
    if (toInput.value.toLowerCase() === user.state.wallet_address.toLowerCase()) {
      return modal.setState(languagePack("자기 자신에게는 전송할 수 없습니다.", language.state));
    }

    if (selected.state === "point") {
      if (Number(point.state) < Number(amountInput.value)) {
        return modal.setState(languagePack("잔액이 부족합니다.", language.state));
      }
    } else {
      if (Number(balance.state[selected.state]) < Number(amountInput.value)) {
        return modal.setState(languagePack("잔액이 부족합니다.", language.state));
      }
    }

    // if (selected.state === CONTRACT_ADDRESS[0].name) {
    //   const {
    //     data: { sum, locked, staking },
    //   } = await Axios.post(`${HOST}/api/unavailable/token`, null, httpHeader());
    //   unavailable.setState({ sum, locked, staking });
    //   if (Number(balance.state[CONTRACT_ADDRESS[0].name]) < Number(sum) + Number(amountInput.value)) {
    //     return modal.setState(languagePack("잔액이 부족합니다.", language.state));
    //   }
    // }

    if (selected.state === "point") {
      const requestBody = {
        amount: amountInput.value,
        to: toInput.value,
      };
      const { data } = await Axios.post(`${HOST}/user/point/send`, requestBody, httpHeader());

      if (data.status) {
        close();
        passwordInput.setValue("");
        amountInput.setValue("");
        toInput.setValue("");
        modal.setState(languagePack("성공적으로 처리되었습니다.", language.state));
        getPoint();
      } else {
        switch (data.code) {
          case "balance_too_low":
            modal.setState(languagePack("잔액이 부족합니다.", language.state));
            break;
          case "invalid receiver":
            modal.setState(languagePack("존재하지 않는 유저 지갑주소입니다.", language.state));
            break;
          default:
            modal.setState(languagePack("전송 오류입니다.", language.state));
            break;
        }
      }
    } else {
      const confirm = () => {
        close();
        passwordInput.setValue("");
        amountInput.setValue("");
        toInput.setValue("");
        modal.setState(languagePack("성공적으로 처리되었습니다.", language.state));
      };

      const reject = () => {
        modal.setState(languagePack("전송 오류입니다.", language.state));
      };

      const sendForm = {
        address: user.state.wallet_address,
        fee: fee.state,
        to: toInput.value,
        amount: amountInput.value,
        isLoading,
        confirm,
        reject,
        symbol: selected.state,
      };
      isLoading.setState(true);
      switch (selected.state) {
        case "eth":
          await sendEth(sendForm);
          return;
        default:
          await sendToken(sendForm);
          return;
      }
    }
  };

  const handleSlider = ({ value, dragging, index, ...restProps }) => {
    fee.setState(value);
    return (
      <Tooltip prefixCls="rc-slider-tooltip" overlay={value} visible={dragging} placement="top" key={index}>
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

  const inputAll = () => {
    if (selected.state === "point") {
      amountInput.setValue(point.state);
    } else {
      amountInput.setValue(balance.state[selected.state]);
    }
  };

  const preload = () => {
    const getGas = async () => {
      const {
        data: { sum, locked, staking },
      } = await Axios.post(`${HOST}/api/unavailable/token`, null, httpHeader());
      unavailable.setState({ sum, locked, staking });

      const { data } = await Axios.post(GAS_PRICE_API);
      gas.setState(data);
      const mark = {};
      mark[data.safeLow] = languagePack("느림", language.state);
      mark[data.average] = languagePack("보통", language.state);
      mark[data.fast] = languagePack("빠름", language.state);
      marks.setState(mark);
    };
    getPoint();
    getGas();
  };

  const getPoint = async () => {
    const { data } = await Axios.post(`${HOST}/user/point`, null, httpHeader());
    point.setState(data);
  };

  useEffect(() => {
    if (user.state && contract.state) {
      getBalance();
    }
  }, [user.state, contract.state]);
  useEffect(preload, []);
  return (
    <PageWrapper>
      <Wrapper>
        <Container>
          {balance.state && <TokenSelect selected={selected} />}
          {balance.state && (
            <>
              {selected.state === "eth" && (
                <TokenRow
                  balance={balance.state[selected.state]}
                  symbol={selected.state}
                  fullName="Ethereum"
                  send={true}
                />
              )}
              {selected.state === "gip" && (
                <TokenRow
                  balance={balance.state[selected.state]}
                  symbol={selected.state}
                  fullName={TOKEN_INFO[selected.state].fullName}
                  logo={TOKEN_INFO[selected.state].symbol}
                  send={true}
                />
              )}
              {selected.state === "point" && (
                <TokenRow
                  balance={point.state}
                  symbol={"GIPK"}
                  fullName={"GIPK"}
                  logo={CONTRACT_ADDRESS[0].symbol}
                  send={true}
                  point={true}
                />
              )}
            </>
          )}

          {/* {unavailable.state && selected.state === CONTRACT_ADDRESS[0].name && (
            <>
              <SideBox>
                <div>{languagePack("락업중인 물량", language.state)}</div>
                <div>
                  {numberWithCommas(ToFixed(Number(unavailable.state.locked), 10000)) +
                    " " +
                    CONTRACT_ADDRESS[0].name.toUpperCase()}
                </div>
              </SideBox>
              <SideBox>
                <div>{languagePack("스테이킹중인 물량", language.state)}</div>
                <div>
                  {numberWithCommas(ToFixed(Number(unavailable.state.locked), 10000)) +
                    " " +
                    CONTRACT_ADDRESS[0].name.toUpperCase()}
                </div>
              </SideBox>
              <SideBox>
                <div>{languagePack("송금 가능액", language.state)}</div>
                <div style={{ color: Theme.indigo }}>
                  {numberWithCommas(
                    ToFixed(balance.state[CONTRACT_ADDRESS[0].name] - Number(unavailable.state.sum), 10000)
                  ) +
                    " " +
                    CONTRACT_ADDRESS[0].name.toUpperCase()}
                </div>
              </SideBox>
            </>
          )} */}

          <QrForm>
            <Input {...amountInput} placeholder={languagePack("보낼 금액", language.state)} />
            <IconWrapper onClick={inputAll}>{languagePack("최대", language.state)}</IconWrapper>
          </QrForm>
          <QrForm>
            <Input {...toInput} placeholder={languagePack("받는 지갑주소", language.state)} />
          </QrForm>
          {selected.state !== "point" && (
            <QrForm>
              <SliderForm>
                <FeeLabel>{languagePack("수수료", language.state)}</FeeLabel>
                {gas.state ? (
                  <Slider
                    style={{ width: "320", marginBottom: 20, backgroundColor: "rgba(255,255,255,0)" }}
                    trackStyle={{ backgroundColor: Theme.mainColor }}
                    dotStyle={{ borderColor: Theme.mainColor }}
                    handleStyle={{ borderColor: Theme.mainColor }}
                    handle={handleSlider}
                    marks={marks.state}
                    min={gas.state.safeLow}
                    defaultValue={gas.state.average}
                    max={gas.state.fast}
                  />
                ) : (
                  <Loader fit={true} color={Theme.darkGrey} size={15} />
                )}
              </SliderForm>
            </QrForm>
          )}
          <Popup
            trigger={
              <ButtonWrapper>
                <DefaultButton disabled={isLoading.state} text={languagePack("송금", language.state)} />
              </ButtonWrapper>
            }
            modal={true}
            contentStyle={{
              padding: 25,
              width: "90%",
              maxWidth: VIEWPORT_MAX_WIDTH,
              border: 0,
              borderRadius: 10,
            }}
          >
            {(close) => (
              <div>
                <PasswordTitle>{languagePack("비밀번호를 입력하세요.", language.state)}</PasswordTitle>
                <Input {...passwordInput} type="password" placeholder="비밀번호" />
                <DefaultButton
                  style={{ marginTop: 10 }}
                  block
                  disabled={isLoading.state}
                  onClick={authOnClick.bind(null, close)}
                  text={languagePack("송금", language.state)}
                />
              </div>
            )}
          </Popup>

          <NotificationWrapper>
            {selected.state !== "point" &&
              gas.state &&
              fee.state < gas.state.safeLow + (gas.state.average - gas.state.safeLow) / 2 && (
                <Alert color="danger">
                  {languagePack("수수료가 너무 낮으면 트랜잭션이 거부될 수 있습니다.", language.state)}
                </Alert>
              )}
          </NotificationWrapper>
        </Container>
      </Wrapper>
    </PageWrapper>
  );
};

export default Page;
