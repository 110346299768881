import React from "react";
import styled from "styled-components";

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const EmptyBox = ({ text = "None" }) => {
  return <Box>{text}</Box>;
};

export default EmptyBox;
