import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import Table from "rc-table";
import Paginate from "react-paginate";

import columns from "./TableColumns";
import GlobalContext from "../../../Hooks/globalContext";
import { HOST, ADMIN_PAGE_UNIT } from "../../../Utils/constants";
import useState from "../../../Hooks/useState";
import Loader from "../../Common/Loader";
import useInput from "../../../Hooks/useInput";
import DefaultButton from "../../Common/DefaultButton";

const CustomInput = styled.input`
  border: 0;
  border: 1px solid #e6e6e6;
  border-radius: 2.5px;
  background-color: #fafafa;
  font-size: 12px;
  box-sizing: border-box;
  max-width: 300px;
  width: 100%;
  padding: 5px;
  outline: none;
  :disabled {
    border: 1px dotted #e6e6e6;
  }
`;

const CustomForm = styled.form`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 300px;
  position: relative;
  input {
    padding-right: 50px;
  }
`;

const CustomButton = styled.button`
  position: absolute;
  border: 0;
  background-color: ${(props) => props.theme.button};
  color: ${(props) => props.theme.mainColor};
  font-size: 13px;
  height: 30px;
  :hover {
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }
`;

const SearchBox = styled.div`
  display: flex;
  font-size: 13px;
`;

const Top = styled.div`
  height: calc(100% - 60px);
  width: 100%;
`;

const Bottom = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

const TableStyles = styled.div`
  padding: 1rem;
  width: 100%;
  max-height: 90%;
  overflow: auto;

  ul {
    justify-content: center;
  }

  table {
    border-spacing: 0;
    border: 1px solid ${(props) => props.theme.darkGrey};
    width: 100%;
    font-size: 13px;
    button {
      border: 0;
      margin: 0;
      color: white;
      background-color: ${(props) => props.theme.mainColor};
      :first-child {
        margin-right: 5px;
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.mainDarkColor};
      }

      :focus {
        outline: none;
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }

      :nth-child(2n) {
        background-color: ${(props) => props.theme.lightGrey};
      }
    }

    th {
      border-bottom: 1px solid ${(props) => props.theme.darkGrey};
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
    td {
      margin: 0;
      padding: 0.5rem;
      border-right: 1px solid ${(props) => props.theme.darkGrey};
      :last-child {
        border-right: 0;
      }
    }
  }
`;

const BottomItem = styled.div``;

const Page = ({ adminPage, hostPath, focus }) => {
  const table = useState(null);
  const page = useState(null);
  const nowPage = useState(0);
  const { httpHeader } = useContext(GlobalContext);

  const preload = () => {
    tableFactory({ selected: 0, init: true });
  };

  const tableFactory = async ({ selected, init = false }) => {
    nowPage.setState(selected);

    page.setState({ ...page.state, loading: true });
    const requestBody = {
      perPage: ADMIN_PAGE_UNIT,
      init,
      selected: selected + 1,
    };
    const { data } = await axios.post(`${HOST}${hostPath}`, requestBody, httpHeader());
    table.setState(data.select);
    page.setState({
      ...page.state,
      loading: false,
      pageCount: Math.ceil(data.count / ADMIN_PAGE_UNIT),
    });
  };

  const writeOnClick = () => {
    adminPage.setState("noticeWrite");
  };

  const column = columns({ page: adminPage, focus, reset: tableFactory });

  useEffect(preload, []);
  return table.state ? (
    <>
      <Top>
        <TableStyles>
          <Table rowKey={({ id }) => id} columns={column.notice} data={table.state} />
        </TableStyles>
      </Top>
      <Bottom>
        <BottomItem></BottomItem>
        <BottomItem>
          {page.state && (
            <Paginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={page.state.pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              onPageChange={tableFactory}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          )}
        </BottomItem>
        <BottomItem>
          <DefaultButton style={{ padding: "10px 15px" }} text="글쓰기" onClick={writeOnClick} />
        </BottomItem>
      </Bottom>
    </>
  ) : (
    <Loader />
  );
};

export default Page;
