export default {
  black: "#151515",
  mainColor: "#3D73AD",
  button: "#FBAA5C",
  bgColor: "#FAFAFA",
  darkGrey: "#999",
  lightGrey: "#e6e6e6",
  navy: "#1D3548",
  navyDarkGrey: "#2A394B",
  navbar: "#c0392b",
  orange: "#F17529",
  indigo: "#3F51B5",
};
