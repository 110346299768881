import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

import { EthereumIcon, RefreshIcon, LockIcon } from "../Icons";
import globalContext from "../../Hooks/globalContext";
import { numberWithCommas, ToFixed } from "../../Utils/util";
import { languagePack } from "../../Utils/language";
import Axios from "axios";
import { HOST } from "../../Utils/constants";
import { useHistory } from "react-router-dom";

const BottomElement = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 80px;
  background-color: white;
`;

const ElementSymbol = styled.div`
  width: 64px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    opacity: 0.75;
  }
`;

const ElementRoot = styled.div`
  display: flex;
  align-items: center;
`;

const ElementTop = styled(ElementRoot)`
  height: 33%;
  font-size: 19px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  color: black;
`;

const ElementBottom = styled(ElementRoot)`
  height: 33%;
  color: ${(props) => props.theme.darkGrey};
  font-size: 15px;
  justify-content: space-between;
  line-height: 18px;
`;

const ElementRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: calc(100% - 64px);
  height: 100%;
`;

const SymbolImage = styled.img`
  width: 38px;
`;

const BalanceLabel = styled.div`
  color: ${(props) => props.theme.darkGrey};
  font-size: 14px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
`;

const Container = ({
  marketcap,
  price,
  balance,
  symbol,
  fullName,
  send = false,
  point = false,
  locked,
  logo,
  pointMain,
}) => {
  const { getBalance, language, balance: _balance, httpHeader, contract } = useContext(globalContext);
  const history = useHistory();

  const onClick = async () => {
    getBalance(symbol);
  };

  const getLocked = async () => {
    const { data } = await Axios.post(`${HOST}/api/lockup`, { tokenType: symbol }, httpHeader());
    locked.setState(Number(data.sum));
  };

  useEffect(() => {
    if (send && !point) {
      if ((!balance || balance === ``) && contract.state) {
        const split = symbol.split(" ")[0];
        getBalance(split);
      }
    }
  }, []);
  return (
    <BottomElement>
      <ElementSymbol onClick={() => history.push("/send/" + symbol)}>
        {symbol === "eth" ? <EthereumIcon size={45} /> : <SymbolImage src={logo} />}
      </ElementSymbol>
      <ElementRight>
        <ElementTop>
          <Label>
            <span>{symbol.toUpperCase()}</span>
          </Label>
          {!pointMain && balance !== undefined && !send && <RefreshIcon color={"grey"} onClick={onClick} size={18} />}
          {pointMain && <RefreshIcon color={"grey"} onClick={pointMain} size={18} />}
          {send && !pointMain && <BalanceLabel>{languagePack("잔액", language.state)}</BalanceLabel>}
        </ElementTop>
        <ElementBottom>
          <span>{fullName}</span>
          {balance !== 0 && !balance ? (
            <ClipLoader sizeUnit={"px"} size={15} color={`Theme.lightGrey`} />
          ) : (
            <span>{`${numberWithCommas(Number(balance))} ${symbol.toUpperCase()}`}</span>
          )}
        </ElementBottom>
        {!send && (
          <ElementBottom style={{ color: "grey" }}>
            <span>
              {marketcap.state && `${numberWithCommas(Number(marketcap.state[`price_${symbol}`]).toFixed(2))}$`}
            </span>
            {balance !== 0 && !balance ? (
              <></>
            ) : (
              <span>
                {marketcap.state &&
                  `${numberWithCommas(Number(marketcap.state[`price_${symbol}`] * balance).toFixed(2))}$`}
              </span>
            )}
          </ElementBottom>
        )}

        {/* <ElementBottom style={{ fontSize: 15, marginTop: 5 }}>
          <span>{price ? `1 ${symbol} : ${ToFixed(Number(price), 10000)}$` : <></>}</span>
          <span>
            {balance >= 0 && price ? `${numberWithCommas(ToFixed(Number(price) * Number(balance)))}$` : <></>}
          </span>
        </ElementBottom> */}
      </ElementRight>
    </BottomElement>
  );
};

export default Container;
