import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import Axios from "axios";

import { AMOUNT_REGEX, CONTRACT_ADDRESS, HOST, NODE_ENDPOINT, NUMBER_REGEX } from "../../../Utils/constants";
import { numberWithCommas, ToFixed } from "../../../Utils/util";
import { languagePack } from "../../../Utils/language";
import useState from "../../../Hooks/useState";

import globalContext from "../../../Hooks/globalContext";
import PageWrapper from "../../../Components/Common/PageWrapper";
import Input from "../../../Components/Common/Input";
import useInput from "../../../Hooks/useInput";
import { ChainIcon } from "../../../Components/Icons";
import DefaultButton from "../../../Components/Common/DefaultButton";
import Loader from "../../../Components/Common/Loader";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow: auto;
`;

const TopBox = styled.div`
  height: 120px;
  width: 100%;
  border-radius: 15px;
  border: 1px solid ${(props) => props.theme.lightGrey};
  box-shadow: ${(props) => props.theme.darkGrey} 1px 1px 3px;
  background: white;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    cursor: pointer;
    opacity: 0.3;
  }
`;

const Ticker = styled.span`
  font-size: 15px;
  line-height: 28px;
  margin-left: 5px;
`;

const InnerSideBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

const InnerCenterBox = styled.div`
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: flex-end;
  font-weight: bold;
`;

const DetailBox = styled.div`
  background-color: white;
  padding: 10px 20px 30px 20px;
  margin-top: 10px;
  div {
    margin-top: 10px;
  }
`;

const ResultBox = styled.div`
  background-color: white;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
`;

const DetailTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
`;

const AmountBox = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  input {
    margin-top: 5px;
    border-radius: 2.5px;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    ::placeholder {
      color: ${(props) => props.theme.darkGrey};
    }
  }
`;

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Symbol = styled.img`
  height: 15px;
  margin-right: 5px;
`;

const StatusBall = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #2cc942;
`;

const TokenLabel = styled.span`
  color: ${(props) => props.theme.darkGrey};
  margin-right: 5px;
`;

const Page = ({ phase }) => {
  const amountInput = useInput({ defaultValue: "", regex: AMOUNT_REGEX });
  const staking = useState(null);
  const isLoading = useState(false);
  const { language, httpHeader, modal, contract, web3, user } = useContext(globalContext);

  const getStaking = async () => {
    const { data } = await Axios.post(`${HOST}/api/staking`, {}, httpHeader());
    staking.setState(data);
  };

  const getResultToken = (number) => {
    try {
      if (isNaN(Number(number))) {
        return 0;
      }
      // return (number * (100 + staking.state.config.staking_per)) / 100;
      return ((number * staking.state.config.staking_per) / 100).toFixed(2);
    } catch (e) {
      return 0;
    }
  };

  const stakingOnClick = async () => {
    if (amountInput.value === "") {
      return modal.setState(languagePack("스테이킹할 갯수를 입력하세요.", language.state));
    }
    if (staking.state.config.staking_available === 0) {
      return modal.setState(languagePack("현재 스테이킹이 가능한 상태가 아닙니다.", language.state));
    }
    if (isNaN(Number(amountInput.value))) {
      return modal.setState(languagePack("올바른 수량을 입력해주세요.", language.state));
    }
    try {
      isLoading.setState(true);
      const tokenBalance = await contract.state.gip.methods.balanceOf(user.state.wallet_address).call();
      const token = await web3.utils.fromWei(tokenBalance);

      const {
        data: { sum },
      } = await Axios.post(`${HOST}/api/unavailable/token`, null, httpHeader());
      if (token - Number(sum) < Number(amountInput.value)) {
        return modal.setState(languagePack("스테이킹 하려는 금액이 현재 잔액보다 큽니다.", language.state));
      }

      const requestBody = {
        amount: Number(amountInput.value),
        configId: staking.state.config.id,
        token,
      };

      const { data } = await Axios.post(`${HOST}/user/staking/token`, requestBody, httpHeader());
      if (data.status) {
        modal.setState(Number(amountInput.value) + languagePack("개의 토큰이 스테이킹 되었습니다.", language.state));
        getStaking();
        amountInput.setValue("");
      } else {
        switch (data.code) {
          case "balance_staking":
            modal.setState(languagePack("현재 스테이킹이 가능한 상태가 아닙니다.", language.state));
            break;
          case "staking_status":
            modal.setState(languagePack("스테이킹 하려는 금액이 현재 잔액보다 큽니다.", language.state));
            break;
          case "config_mismatch":
            modal.setState(
              languagePack("앱 설정이 일치하지 않습니다. 앱을 재부팅 후 다시 시도해주세요.", language.state)
            );
            break;
          case "minimum_amount":
            modal.setState(languagePack("스테이킹하려는 갯수가 최소 스테이킹 갯수보다 적습니다.", language.state));
            break;
          case "server":
            modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
            break;
        }
      }
    } catch (e) {
      console.log(e);
      modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
    } finally {
      isLoading.setState(false);
    }
  };

  useEffect(() => {
    getStaking();
  }, []);

  return (
    <PageWrapper>
      {staking.state ? (
        <Wrapper>
          <TopBox
            onClick={() => {
              phase.setState(1);
            }}
          >
            <InnerSideBox>
              <SymbolWrapper style={{ display: "flex" }}>
                {/* <Symbol src={Logo} /> */}
                <div>GIP</div>
              </SymbolWrapper>
              <div>{languagePack("스테이킹 중", language.state)}</div>
            </InnerSideBox>
            <InnerCenterBox>
              {numberWithCommas(ToFixed(staking.state.staking.sum))}
              <Ticker>GIP</Ticker>
            </InnerCenterBox>
            <InnerSideBox>
              <div />
              {staking.state.config.staking_available === 0 ? (
                <SymbolWrapper>
                  <TokenLabel>CLOSE</TokenLabel>
                  <StatusBall style={{ backgroundColor: `#FF564E` }} />
                </SymbolWrapper>
              ) : (
                <SymbolWrapper>
                  <TokenLabel>OPEN</TokenLabel>
                  <StatusBall />
                </SymbolWrapper>
              )}
            </InnerSideBox>
          </TopBox>
          <DetailBox>
            <DetailTitle>Staking</DetailTitle>
            <InnerSideBox>
              <div>{languagePack("기간", language.state)}</div>
              <div>{staking.state.config.staking_day} day</div>
            </InnerSideBox>
            <InnerSideBox>
              <div>{languagePack("스테이킹 사이클", language.state)}</div>
              <div>{numberWithCommas(staking.state.config.staking_reward_cycle)} day</div>
            </InnerSideBox>
            <InnerSideBox>
              <div>{languagePack("지급 비율", language.state)}</div>
              <div>{staking.state.config.staking_per} % </div>
            </InnerSideBox>
            <InnerSideBox>
              <div>{languagePack("최소 스테이킹 수량", language.state)}</div>
              <div>{numberWithCommas(staking.state.config.staking_min)}</div>
            </InnerSideBox>
            <InnerSideBox>
              <AmountBox>
                <div>{languagePack("수량", language.state)}</div>
                <Input placeholder={languagePack("스테이킹할 갯수를 입력하세요.", language.state)} {...amountInput} />
              </AmountBox>
            </InnerSideBox>
          </DetailBox>
          <ResultBox>
            <div>{languagePack("기댓값", language.state)}</div>
            <ChainIcon color="black" />
            <div>{getResultToken(amountInput.value)} Point</div>
          </ResultBox>
          <DefaultButton
            disabled={isLoading.state}
            onClick={stakingOnClick}
            text={languagePack("스테이킹", language.state)}
          />
        </Wrapper>
      ) : (
        <Loader size={15} color={"black"} />
      )}
    </PageWrapper>
  );
};

export default Page;
