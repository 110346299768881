import React, { useContext } from "react";
import styled from "styled-components";

import globalContext from "../../Hooks/globalContext";
import Background from "../../Resources/Images/background.png";

const Logo = styled.img`
  width: 200px;
  height: 26px;
`;

const SideBar = styled.div`
  background-image: url(${Background});
  background-size: cover;
  background-color: black;
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 5px 2.5px ${(props) => props.theme.darkGrey};
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  padding: 10px;
  color: white;
  font-size: 25px;
  font-weight: bold;
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 15px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  :hover {
    opacity: 0.35;
  }
`;

const FocusedMenuItem = styled(MenuItem)`
  opacity: 0.35;
`;

const AdminSideBar = ({ page }) => {
  const { user } = useContext(globalContext);
  const MenuFactory = ({ text = "None", pageName = null }) => {
    const menuOnClick = () => {
      page.setState(pageName);
    };
    return page.state === pageName ? (
      <FocusedMenuItem onClick={menuOnClick}>{text}</FocusedMenuItem>
    ) : (
      <MenuItem onClick={menuOnClick}>{text}</MenuItem>
    );
  };

  return (
    <SideBar>
      <LogoWrapper>WALLET ADMIN</LogoWrapper>
      <MenuFactory pageName={"userList"} text={"유저 관리"} />
      <MenuFactory pageName={"balance"} text={"유저 잔액"} />
      <MenuFactory pageName={"sendLog"} text={"전송 로그"} />
      <MenuFactory pageName={"lockupList"} text={"락업 로그"} />
      <MenuFactory pageName={"shopList"} text={"가맹점 관리"} />
      <MenuFactory pageName={"withdraw"} text={"출금 신청 관리"} />
      <MenuFactory pageName={"swapList"} text={"스왑 로그"} />
      <MenuFactory pageName={"stakingList"} text={"스테이킹 로그"} />
      <MenuFactory pageName={"shopExchange"} text={"(가맹점전용)원화 환전 조회"} />
      {/* <MenuFactory pageName={"noticeList"} text={"공지사항 관리"} /> */}
      <MenuFactory pageName={"config"} text={"설정"} />
    </SideBar>
  );
};

export default AdminSideBar;
