export const languageAction = (index, language) => {
  switch (index) {
    case "한국어":
      return "한국어";
    case "영어":
      return "English";
    default:
      return "None";
  }
};

export const languagePack = (index, language) => {
  switch (language) {
    case "korean":
      switch (index) {
        case "안드로이드 앱 다운로드":
          return "안드로이드 앱 다운로드";
        case "잘못된 핀번호입니다.":
          return "잘못된 핀번호입니다.";
        case "회원가입이 완료되었습니다.":
          return "회원가입이 완료되었습니다.";
        case "비밀번호를 입력하세요.":
          return "비밀번호를 입력하세요.";
        case "GIPK 전환":
          return "GIPK 전환";
        case "존재하지 않는 유저 지갑주소입니다.":
          return "존재하지 않는 유저 지갑주소입니다.";
        case "자기 자신에게는 전송할 수 없습니다.":
          return "자기 자신에게는 전송할 수 없습니다.";
        case "공지사항이 없습니다.":
          return "공지사항이 없습니다.";
        case "신청할 포인트":
          return "신청할 포인트";
        case "공지사항":
          return "공지사항";
        case "토큰 스왑":
          return "토큰 스왑";
        case "송금 가능액":
          return "송금 가능액";
        case "락업중인 물량":
          return "락업중인 물량";
        case "스테이킹중인 물량":
          return "스테이킹중인 물량";
        case "최소 스테이킹 수량":
          return "최소 스테이킹 수량";
        case "스테이킹 사이클":
          return "스테이킹 사이클";
        case "스테이킹시점 GIP 시세":
          return "스테이킹시점 GIP 시세";
        case "현재 GIP 시세":
          return "현재 GIP 시세";
        case "포인트 전환":
          return "포인트 전환";
        case "1 사이클당 보상":
          return "1 사이클당 보상";

        /**
         *
         */

        case "수령한 보상":
          return "수령한 보상";
        case "수령한 보상(추천인)":
          return "수령한 보상(추천인)";
        case "스테이킹 상세":
          return "스테이킹 상세";
        case "스테이킹 총량":
          return "스테이킹 총량";
        case "10일당 보상":
          return "10일당 보상";
        case "수령 가능 보상":
          return "수령 가능 보상";
        case "수령 가능 보상(추천인)":
          return "수령 가능 보상(추천인)";

        case "옵션":
          return "옵션";
        case "언어":
          return "언어";
        case "기록이 없습니다.":
          return "기록이 없습니다.";
        case "최대":
          return "최대";
        case "스테이킹할 갯수를 입력하세요.":
          return "스테이킹할 갯수를 입력하세요.";
        case "기간":
          return "기간";
        case "이자율":
          return "이자율";
        case "수량":
          return "수량";
        case "기댓값":
          return "기댓값";
        case "스테이킹 중":
          return "스테이킹 중";
        case "스테이킹":
          return "스테이킹";
        case "올바른 수량을 입력해주세요.":
          return "올바른 수량을 입력해주세요.";
        case "스테이킹 하려는 금액이 현재 잔액보다 큽니다.":
          return "스테이킹 하려는 금액이 현재 잔액보다 큽니다.";
        case "앱 설정이 일치하지 않습니다. 앱을 재부팅 후 다시 시도해주세요.":
          return "앱 설정이 일치하지 않습니다. 앱을 재부팅 후 다시 시도해주세요.";
        case "스테이킹하려는 갯수가 최소 스테이킹 갯수보다 적습니다.":
          return "스테이킹하려는 갯수가 최소 스테이킹 갯수보다 적습니다.";
        case "개의 토큰이 스테이킹 되었습니다." /** e.g.) 100개의 토큰이... 10개의 토큰이.. 등 숫자가 맨 앞에 옴.*/:
          return "개의 토큰이 스테이킹 되었습니다.";
        case "모두 받기":
          return "모두 받기";
        case "지급 비율":
          return "지급 비율";
        case "스테이킹 중":
          return "스테이킹 중";
        case "보상 수령 가능":
          return "보상 수령 가능";
        case "지급 됨":
          return "지급 됨";
        case "스테이킹 상태":
          return "스테이킹 상태";
        case "수령 가능한 보상이 없습니다.":
          return "수령 가능한 보상이 없습니다.";
        case "포인트가 지급되었습니다." /** e.g.) 100 포인트가 .. 200 포인트가... 등 포인트 값 n이 맨 앞에 옴 */:
          return "포인트가 지급되었습니다.";
        case "길게 눌러서 새로고침":
          return "길게 눌러서 새로고침";
        case "2차 비밀번호 변경":
          return "2차 비밀번호 변경";
        case "등록된 2차 비밀번호를 입력해주세요.":
          return "등록된 2차 비밀번호를 입력해주세요.";
        case "새 2차 비밀번호를 입력해주세요.":
          return "새 2차 비밀번호를 입력해주세요.";
        case "현재 스테이킹 가능 금액보다 입력한 금액이 더 큽니다.":
          return "현재 스테이킹 가능 금액보다 입력한 금액이 더 큽니다.";
        case "현재 송금 가능 금액보다 보낼 금액이 더 큽니다.":
          return "현재 송금 가능 금액보다 보낼 금액이 더 큽니다.";
        case "송금 가능액":
          return "송금 가능액";
        case "현재 스테이킹이 가능한 상태가 아닙니다.":
          return "현재 스테이킹이 가능한 상태가 아닙니다.";
        case "* 기존 지갑을 등록할 시에는 스테이킹 기능을 이용할 수 없습니다. 이용에 참고하세요.":
          return "* 기존 지갑을 등록할 시에는 스테이킹 기능을 이용할 수 없습니다. 이용에 참고하세요.";
        case "지갑이 등록되었습니다. 다시 로그인 해주세요.":
          return "지갑이 등록되었습니다. 다시 로그인 해주세요.";
        case "스테이킹 불가 계정입니다.":
          return "스테이킹 불가 계정입니다.";
        case "스왑 신청":
          return "스왑 신청";
        case "초기화":
          return "초기화";
        case "모든 항목을 입력해주세요.":
          return "모든 항목을 입력해주세요.";
        case "스왑 계산":
          return "스왑 계산";
        case "올바른 연락처를 입력해주세요.":
          return "올바른 연락처를 입력해주세요.";
        case "컨트랙트 호출":
          return "컨트랙트 호출";
        case "수수료가 너무 낮으면 트랜잭션이 거부될 수 있습니다.":
          return "수수료가 너무 낮으면 트랜잭션이 거부될 수 있습니다.";
        case "존재하지 않는 ID 입니다.":
          return "존재하지 않는 ID 입니다.";
        case "잘못된 ID 혹은 비밀번호입니다.":
          return "잘못된 ID 혹은 비밀번호입니다.";
        case "인증번호 전송":
          return "인증번호 전송";
        case "인증":
          return "인증";
        case "중복된 ID 입니다.":
          return "중복된 ID 입니다.";
        case "로그인":
          return "로그인";
        case "홈":
          return "홈";
        case "등록할 지갑 정보":
          return "등록할 지갑 정보";
        case "버전":
          return "버전";
        case "내역이 없습니다.":
          return "내역이 없습니다.";
        case "추천 코드":
          return "추천 코드";
        case "회원가입":
          return "회원가입";
        case "비밀번호 찾기":
          return "비밀번호 찾기";
        case "이메일":
          return "이메일";
        case "패키지를 선택해주세요.":
          return "패키지를 선택해주세요.";
        case "비밀번호":
          return "비밀번호";
        case "비밀번호 확인":
          return "비밀번호 확인";
        case "추천인":
          return "추천인";
        case "돌아가기":
          return "돌아가기";
        case "더 보기":
          return "더 보기";
        case "확인":
          return "확인";
        case "약관":
          return "약관";
        case "삭제":
          return "삭제";
        case "로그아웃":
          return "로그아웃";
        case "지갑":
          return "지갑";
        case "히스토리":
          return "히스토리";
        case "출금":
          return "출금";
        case "자산":
          return "자산";
        case "잔액":
          return "잔액";
        case "수수료":
          return "수수료";
        case "가스":
          return "가스";
        case "송금":
          return "송금";
        case "별명":
          return "별명";
        case "등록":
          return "등록";
        case "지갑 주소":
          return "지갑 주소";
        case "받는 지갑주소":
          return "받는 지갑주소";
        case "보낼 금액":
          return "보낼 금액";
        case "비밀번호 변경":
          return "비밀번호 변경";
        case "추천인 코드":
          return "추천인 코드";
        case "가격":
          return "갸격";
        case "설정":
          return "설정";
        case "클립보드로 복사":
          return "클립보드로 복사";
        case "보낸 항목":
          return "보낸 항목";
        case "받은 항목":
          return "받은 항목";
        case "보낼 금액을 입력하세요.":
          return "보낼 금액을 입력하세요.";
        case "수수료를 선택하세요.":
          return "수수료를 선택하세요.";
        case "나의 자산":
          return "나의 자산";
        case "빠름":
          return "빠름";
        case "보통":
          return "보통";
        case "느림":
          return "느림";
        case "등록된 지갑이 없습니다.":
          return "등록된 지갑이 없습니다.";
        case "올바른 지갑주소가 아닙니다.":
          return "올바른 지갑주소가 아닙니다.";
        case "지갑주소가 존재하지 않습니다.":
          return "지갑주소가 존재하지 않습니다.";
        case "받는 사람의 주소를 입력하세요.":
          return "받는 사람의 주소를 입력하세요.";
        case "개인 키를 입력하세요.":
          return "개인 키를 입력하세요.";
        case "개인 키는 서버에 저장되지 않습니다.":
          return "개인 키는 서버에 저장되지 않습니다.";
        case "지갑 주소가 복사되었습니다.":
          return "지갑 주소가 복사되었습니다.";
        case "기록이 존재하지 않습니다.":
          return "기록이 존재하지 않습니다.";
        case "추천인 코드가 복사되었습니다.":
          return "추천인 코드가 복사되었습니다.";
        case "성공적으로 처리되었습니다.":
          return "성공적으로 처리되었습니다.";
        case "서버 오류입니다. 잠시 후에 다시 시도해주세요.":
          return "서버 오류입니다. 잠시 후에 다시 시도해주세요.";
        case "잠시 후에 다시 시도해주세요.":
          return "잠시 후에 다시 시도해주세요.";
        case "추천인 코드를 등록했습니다.":
          return "추천인 코드를 등록했습니다.";
        case "이미 등록된 추천인 코드가 있습니다.":
          return "이미 등록된 추천인 코드가 있습니다.";
        case "자기 자신은 등록할 수 없습니다.":
          return "자기 자신은 등록할 수 없습니다.";
        case "잔액이 부족합니다.":
          return "잔액이 부족합니다.";
        case "잘못된 요청입니다.":
          return "잘못된 요청입니다.";
        case "인증에 실패하였습니다. 재로그인 해주세요.":
          return "인증에 실패하였습니다. 재로그인 해주세요.";
        case "잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.":
          return "잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.";
        case "비밀번호가 변경되었습니다. 다시 로그인해주세요.":
          return "비밀번호가 변경되었습니다. 다시 로그인해주세요.";
        case "두 비밀번호가 맞지 않습니다.":
          return "두 비밀번호가 맞지 않습니다.";
        case "보안을 위해 로그아웃합니다. 다시 로그인해주세요.":
          return "보안을 위해 로그아웃합니다. 다시 로그인해주세요.";
        case "출금할 금액을 입력하세요.":
          return "출금할 금액을 입력하세요.";
        case "전송 오류입니다.":
          return "전송 오류입니다.";
        case "트랜잭션 전송 완료.":
          return "트랜잭션 전송 완료.";
        case "시간 초과입니다. 처음부터 다시 시도해주세요.":
          return "시간 초과입니다. 처음부터 다시 시도해주세요.";
        case "존재하지 않는 E-Mail입니다.":
          return "존재하지 않는 E-Mail입니다.";
        case "잘못된 코드입니다.":
          return "잘못된 코드입니다.";
        case "메일 인증이 완료되었습니다. 지갑정보를 확인하세요.":
          return "메일 인증이 완료되었습니다. 지갑정보를 확인하세요.";
        case "비밀번호 변경이 완료되었습니다.":
          return "비밀번호 변경이 완료되었습니다.";
        case "개인 키가 복사되었습니다.":
          return "개인 키가 복사되었습니다.";
        case "인증 번호를 전송했습니다.":
          return "인증 번호를 전송했습니다.";
        case "5분 안에 이메일에 전송된 코드를 입력하세요.":
          return "5분 안에 이메일에 전송된 코드를 입력하세요.";
        case "중복된 E-Mail입니다.":
          return "중복된 E-Mail입니다.";
        case "존재하지 않는 추천인입니다.":
          return "존재하지 않는 추천인입니다.";
        case "잘못된 E-Mail 혹은 비밀번호입니다.":
          return "잘못된 E-Mail 혹은 비밀번호입니다.";
        case "당신의 지갑주소입니다. QR코드를 스캔하세요.":
          return "당신의 지갑주소입니다. QR코드를 스캔하세요.";
        case "개인 키를 클릭하여 복사하세요.":
          return "개인 키를 클릭하여 복사하세요.";
        case "개인 키는 반드시 별도로 저장해주세요.":
          return "개인 키는 반드시 별도로 저장해주세요.";
        case "* 서버에 저장되지 않습니다. *":
          return "* 서버에 저장되지 않습니다. *";
        case "지갑 주소를 클릭하여 복사하세요.":
          return "지갑 주소를 클릭하여 복사하세요.";
        case "약관에 동의하지 않으면 서비스를 이용할 수 없습니다.":
          return "약관에 동의하지 않으면 서비스를 이용할 수 없습니다.";
        case "알림":
          return "알림";
        case "자동 로그인":
          return "자동 로그인";
        case "이메일 인증":
          return "이메일 인증";
        case "수익":
          return "수익";
        case "포인트":
          return "포인트";
        case "코인":
          return "코인";
        case "잔고 확인":
          return "잔고 확인";
        case "ETH 보내기":
          return "ETH 보내기";
        case "APK 다운로드":
          return "APK 다운로드";
        case "아이디":
          return "아이디";
        case "레퍼럴 코드":
          return "레퍼럴 코드";
        case "주소 공유":
          return "주소 공유";
        case "주소 복사":
          return "주소 복사";
        case "받을사람 주소":
          return "받을사람 주소";
        case "답변완료":
          return "답변완료";
        case "답변대기":
          return "답변대기";
        case "보낼 수량":
          return "보낼 수량";
        case "제목":
          return "제목";
        case "문의하기":
          return "문의하기";
        case "매칭 정보":
          return "매칭 정보";
        case "알림이 없습니다.":
          return "알림이 없습니다.";
        case "ETH 잔액":
          return "ETH 잔액";
        case "변경된 설정이 없습니다.":
          return "변경된 설정이 없습니다.";
        case "지갑 생성":
          return "지갑 생성";
        default:
          return "None";
      }

    /**
     * *************************************************************
     */

    case "english":
      switch (index) {
        case "포인트 전환":
          return "Exchange(point)";
        case "수령한 보상":
          return "Received compensation";
        case "수령한 보상(추천인)":
          return "Received compensation (reco)";
        case "스테이킹 상세":
          return "Staking details";
        case "스테이킹 총량":
          return "Total amount of staking";
        case "10일당 보상":
          return "10 days compensation";
        case "수령 가능 보상":
          return "Available compensation";
        case "수령 가능 보상(추천인)":
          return "Available compensation (reco)";

        case "옵션":
          return "option";
        case "언어":
          return "language";
        case "기록이 없습니다.":
          return "There are no records.";
        case "최대":
          return "max";
        case "스테이킹할 갯수를 입력하세요.":
          return "Please enter the number of stakes.";
        case "기간":
          return "period";
        case "이자율":
          return "interest rate";
        case "수량":
          return "quantity";
        case "기댓값":
          return "expected value";
        case "스테이킹 중":
          return "Staking";
        case "스테이킹":
          return "staking";
        case "올바른 수량을 입력해주세요.":
          return "Please enter the correct quantity.";
        case "스테이킹 하려는 금액이 현재 잔액보다 큽니다.":
          return "The amount you want to stake is greater than your current balance.";
        case "앱 설정이 일치하지 않습니다. 앱을 재부팅 후 다시 시도해주세요.":
          return "The app settings do not match. Please reboot the app and try again.";
        case "스테이킹하려는 갯수가 최소 스테이킹 갯수보다 적습니다.":
          return "The number of stakes you want to stake is less than the minimum stakes.";
        case "개의 토큰이 스테이킹 되었습니다." /** e.g.) 100개의 토큰이... 10개의 토큰이.. 등 숫자가 맨 앞에 옴.*/:
          return "The tokens have been staked.";
        case "모두 받기":
          return "receive all";
        case "지급 비율":
          return "payment rate";
        case "스테이킹 중":
          return "Staking";
        case "보상 수령 가능":
          return "Reward can be received";
        case "지급 됨":
          return "paid";
        case "스테이킹 상태":
          return "Staking status";
        case "수령 가능한 보상이 없습니다.":
          return "There are no rewards available.";
        case "포인트가 지급되었습니다." /** e.g.) 100 포인트가 .. 200 포인트가... 등 포인트 값 n이 맨 앞에 옴 */:
          return "Points have been awarded.";
        case "길게 눌러서 새로고침":
          return "Press and hold to refresh";
        case "2차 비밀번호 변경":
          return "2nd password change";
        case "등록된 2차 비밀번호를 입력해주세요.":
          return "Please enter your registered secondary password.";
        case "새 2차 비밀번호를 입력해주세요.":
          return "Please enter a new secondary password.";
        case "현재 스테이킹 가능 금액보다 입력한 금액이 더 큽니다.":
          return "The amount entered is larger than the current amount available for staking.";
        case "현재 송금 가능 금액보다 보낼 금액이 더 큽니다.":
          return "The amount to be sent is larger than the current amount that can be sent.";
        case "송금 가능액":
          return "transferable amount";
        case "현재 스테이킹이 가능한 상태가 아닙니다.":
          return "Staking is not currently available.";
        case "* 기존 지갑을 등록할 시에는 스테이킹 기능을 이용할 수 없습니다. 이용에 참고하세요.":
          return "* When registering an existing wallet, the staking function is not available. Please refer to the usage.";
        case "지갑이 등록되었습니다. 다시 로그인 해주세요.":
          return "Your wallet has been registered. Please log in again.";
        case "스테이킹 불가 계정입니다.":
          return "This account is not staking.";
        case "스왑 신청":
          return "Apply for swap";
        case "초기화":
          return "initialize";
        case "모든 항목을 입력해주세요.":
          return "Please enter all items.";
        case "스왑 계산":
          return "swap calculation";
        case "올바른 연락처를 입력해주세요.":
          return "Please enter a valid contact information.";
        case "컨트랙트 호출":
          return "contract call";
        case "수수료가 너무 낮으면 트랜잭션이 거부될 수 있습니다.":
          return "If the fee is too low, the transaction may be rejected.";
        case "존재하지 않는 ID 입니다.":
          return "This ID does not exist.";
        case "잘못된 ID 혹은 비밀번호입니다.":
          return "Invalid ID or password.";
        case "인증번호 전송":
          return "Send authentication number";
        case "인증":
          return "authentication";
        case "중복된 ID 입니다.":
          return "Duplicate ID.";
        case "로그인":
          return "login";
        case "홈":
          return "home";
        case "등록할 지갑 정보":
          return "wallet information to register";
        case "버전":
          return "version";
        case "내역이 없습니다.":
          return "There is no history.";
        case "추천 코드":
          return "recommended code";
        case "회원가입":
          return "Sign Up";
        case "비밀번호 찾기":
          return "Find password";
        case "이메일":
          return "email";
        case "패키지를 선택해주세요.":
          return "Please select a package.";
        case "비밀번호":
          return "password";
        case "비밀번호 확인":
          return "Confirm password";
        case "추천인":
          return "recommender";
        case "돌아가기":
          return "go back";
        case "더 보기":
          return "Show more";
        case "확인":
          return "OK";
        case "약관":
          return "Terms";
        case "삭제":
          return "delete";
        case "로그아웃":
          return "logout";
        case "지갑":
          return "wallet";
        case "히스토리":
          return "history";
        case "출금":
          return "withdrawal";
        case "자산":
          return "asset";
        case "잔액":
          return "balance";
        case "수수료":
          return "fee";
        case "가스":
          return "gas";
        case "송금":
          return "transfer";
        case "별명":
          return "alias";
        case "등록":
          return "register";
        case "지갑 주소":
          return "wallet address";
        case "받는 지갑주소":
          return "receiving wallet address";
        case "보낼 금액":
          return "Amount to send";
        case "비밀번호 변경":
          return "Change password";
        case "추천인 코드":
          return "recommender code";
        case "가격":
          return "cheap";
        case "설정":
          return "set";
        case "클립보드로 복사":
          return "copy to clipboard";
        case "보낸 항목":
          return "sent";
        case "받은 항목":
          return "Item received";
        case "보낼 금액을 입력하세요.":
          return "Please enter the amount to be sent.";
        case "수수료를 선택하세요.":
          return "Please select a fee.";
        case "나의 자산":
          return "My Assets";
        case "빠름":
          return "fast";
        case "보통":
          return "normal";
        case "느림":
          return "slow";
        case "등록된 지갑이 없습니다.":
          return "There is no registered wallet.";
        case "올바른 지갑주소가 아닙니다.":
          return "It is not a valid wallet address.";
        case "지갑주소가 존재하지 않습니다.":
          return "The wallet address does not exist.";
        case "받는 사람의 주소를 입력하세요.":
          return "Please enter the recipient's address.";
        case "개인 키를 입력하세요.":
          return "Please enter your private key.";
        case "개인 키는 서버에 저장되지 않습니다.":
          return "The private key is not stored on the server.";
        case "지갑 주소가 복사되었습니다.":
          return "Your wallet address has been copied.";
        case "기록이 존재하지 않습니다.":
          return "The record does not exist.";
        case "추천인 코드가 복사되었습니다.":
          return "The referral code has been copied.";
        case "성공적으로 처리되었습니다.":
          return "Processed successfully.";
        case "서버 오류입니다. 잠시 후에 다시 시도해주세요.":
          return "Server error. Please try again later.";
        case "잠시 후에 다시 시도해주세요.":
          return "Please try again later.";
        case "추천인 코드를 등록했습니다.":
          return "You have registered a referral code.";
        case "이미 등록된 추천인 코드가 있습니다.":
          return "You already have a registered referral code.";
        case "자기 자신은 등록할 수 없습니다.":
          return "You cannot register yourself.";
        case "잔액이 부족합니다.":
          return "Not enough balance.";
        case "잘못된 요청입니다.":
          return "Bad request.";
        case "인증에 실패하였습니다. 재로그인 해주세요.":
          return "Authentication failed. Please log in again.";
        case "잘못된 비밀번호입니다. 확인 후 다시 시도해주세요.":
          return "Invalid password. Please check and try again.";
        case "비밀번호가 변경되었습니다. 다시 로그인해주세요.":
          return "Your password has been changed. Please log in again.";
        case "두 비밀번호가 맞지 않습니다.":
          return "The two passwords do not match.";
        case "보안을 위해 로그아웃합니다. 다시 로그인해주세요.":
          return "Logout for security. Please log in again.";
        case "출금할 금액을 입력하세요.":
          return "Please enter the amount to be withdrawn.";
        case "전송 오류입니다.":
          return "Transfer error.";
        case "트랜잭션 전송 완료.":
          return "Transfer completed.";
        case "시간 초과입니다. 처음부터 다시 시도해주세요.":
          return "Timeout. Please try again from the beginning.";
        case "존재하지 않는 E-Mail입니다.":
          return "E-Mail does not exist.";
        case "잘못된 코드입니다.":
          return "Invalid code.";
        case "메일 인증이 완료되었습니다. 지갑정보를 확인하세요.":
          return "E-mail verification has been completed. Please check your wallet information.";
        case "비밀번호 변경이 완료되었습니다.":
          return "Your password change has been completed.";
        case "개인 키가 복사되었습니다.":
          return "The private key has been copied.";
        case "인증 번호를 전송했습니다.":
          return "Your verification number has been sent.";
        case "5분 안에 이메일에 전송된 코드를 입력하세요.":
          return "Please enter the code sent to the email within 5 minutes.";
        case "중복된 E-Mail입니다.":
          return "Duplicate E-Mail.";
        case "존재하지 않는 추천인입니다.":
          return "This referral does not exist.";
        case "잘못된 E-Mail 혹은 비밀번호입니다.":
          return "Invalid E-Mail or Password.";
        case "당신의 지갑주소입니다. QR코드를 스캔하세요.":
          return "This is your wallet address. Scan the QR code.";
        case "개인 키를 클릭하여 복사하세요.":
          return "Click the private key to copy it.";
        case "개인 키는 반드시 별도로 저장해주세요.":
          return "Please be sure to save your private key separately.";
        case "* 서버에 저장되지 않습니다. *":
          return "* Will not be saved on the server. *";
        case "지갑 주소를 클릭하여 복사하세요.":
          return "Click the wallet address to copy it.";
        case "약관에 동의하지 않으면 서비스를 이용할 수 없습니다.":
          return "If you do not agree to the terms and conditions, you will not be able to use the service.";
        case "알림":
          return "notification";
        case "자동 로그인":
          return "auto login";
        case "이메일 인증":
          return "Email verification";
        case "수익":
          return "revenue";
        case "포인트":
          return "point";
        case "코인":
          return "coin";
        case "잔고 확인":
          return "Check balance";
        case "ETH 보내기":
          return "Send ETH";
        case "APK 다운로드":
          return "Download APK";
        case "아이디":
          return "ID";
        case "레퍼럴 코드":
          return "referral code";
        case "주소 공유":
          return "share address";
        case "주소 복사":
          return "copy address";
        case "받을사람 주소":
          return "recipient address";
        case "답변완료":
          return "Answer completed";
        case "답변대기":
          return "wait for reply";
        case "보낼 수량":
          return "Quantity to send";
        case "제목":
          return "title";
        case "문의하기":
          return "Contact Us";
        case "매칭 정보":
          return "Matching information";
        case "알림이 없습니다.":
          return "No notification.";
        case "ETH 잔액":
          return "ETH balance";
        case "변경된 설정이 없습니다.":
          return "No settings have been changed.";
        case "지갑 생성":
          return "create wallet";
        default:
          return "*";
      }

    /**
     * *************************************************************
     */

    default:
      return "None";
  }
};
