import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { ClipLoader } from "react-spinners";

import { languagePack } from "../../Utils/language";
import GlobalContext from "../../Hooks/globalContext";
import { HOST, NOTICE_PAGE_UNIT } from "../../Utils/constants";
import useState from "../../Hooks/useState";
import { dateConvertor, noticeDateConvertor } from "../../Utils/util";
import EmptyBox from "../../Components/Common/EmptyBox";
import GoBack from "../../Components/Common/GoBack";
import Loader from "../../Components/Common/Loader";
import PageWrapper from "../../Components/Common/PageWrapper";

const Notice = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 10px;
`;

const Row = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  cursor: pointer;
  color: black;
  :hover {
    opacity: 0.7;
  }
  :not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.darkGrey};
  }
`;

const Title = styled.div`
  width: 70%;
`;

const CreatedAt = styled.div`
  color: ${(props) => props.theme.darkGrey};
  width: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const MoreButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  font-size: 17px;
  cursor: pointer;
  :hover {
    opacity: 0.75;
  }
`;

const NoticePage = ({ history }) => {
  const { httpHeader, language } = useContext(GlobalContext);
  const page = useState(0);
  const notice = useState(null);
  const more = useState(false);

  const NoticeRow = ({ row, language }) => {
    const onClick = () => {
      history.push(`/notice/${row.id}`);
    };

    let langType = "kor";

    switch (language.state) {
      case "korean":
        langType = "kor";
        break;
      case "english":
        langType = "eng";
        break;
      case "chinese":
        langType = "chn";
        break;
      case "japanese":
        langType = "jap";
        break;
    }

    return (
      <Row onClick={onClick}>
        <Title>{row[`title_${langType}`]}</Title>
        <CreatedAt>{noticeDateConvertor(new Date(row.createdAt))}</CreatedAt>
      </Row>
    );
  };

  const moreOnClick = async () => {
    more.setState(true);
    const next = page.state + 1;
    const requestBody = {
      page: next,
      unit: NOTICE_PAGE_UNIT,
    };
    page.setState(next);
    const { data } = await axios.post(`${HOST}/notice/list`, requestBody, httpHeader());

    const concat = notice.state.select.concat(data.select);

    notice.setState({ count: data.count, select: concat });

    more.setState(false);
  };

  const preload = () => {
    const init = async () => {
      const requestBody = {
        page: page.state,
        unit: NOTICE_PAGE_UNIT,
      };
      const { data } = await axios.post(`${HOST}/notice/list`, requestBody, httpHeader());
      notice.setState(data);
    };
    init();
  };

  useEffect(preload, []);

  return (
    <PageWrapper>
      <GoBack onClick={() => history.goBack()} />
      <Notice>
        {notice.state ? (
          <>
            {notice.state.select.length === 0 ? (
              <EmptyBox text={languagePack("공지사항이 없습니다.", language.state)} />
            ) : (
              <>
                {notice.state.select.map((row, index) => {
                  return <NoticeRow key={index} row={row} language={language} />;
                })}
                {notice.state.select.length < notice.state.count &&
                  (more.state ? (
                    <MoreButton>
                      <ClipLoader sizeUnit={"px"} size={15} color={"#747474"} />
                    </MoreButton>
                  ) : (
                    <MoreButton onClick={moreOnClick}>{languagePack("더 보기", language.state)}</MoreButton>
                  ))}
              </>
            )}
          </>
        ) : (
          <Loader />
        )}
      </Notice>
    </PageWrapper>
  );
};

export default NoticePage;
