import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import axios from "axios";
import { ClipLoader } from "react-spinners";

import { languagePack } from "../../../Utils/language";
import GlobalContext from "../../../Hooks/globalContext";
import { HOST } from "../../../Utils/constants";
import useState from "../../../Hooks/useState";
import { dateConvertor, noticeDateConvertor } from "../../../Utils/util";
import Loader from "../../../Components/Common/Loader";
import GoBack from "../../../Components/Common/GoBack";
import PageWrapper from "../../../Components/Common/PageWrapper";

const Notice = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 10px;
`;

const DetailBox = styled.div``;

const DetailTitle = styled.div`
  width: 100%;
  font-size: 20px;
`;

const DetailCreatedAt = styled.div`
  width: 100%;
  color: ${(props) => props.theme.darkGrey};
  font-size: 13px;
  margin-bottom: 15px;
`;

const DetailContent = styled.div`
  width: 100%;
  font-size: 13px;
`;

const DetailInnerBox = styled.div`
  margin-top: 15px;
  padding: 5px;
`;

const NoticePage = ({ history, match }) => {
  const { httpHeader, language } = useContext(GlobalContext);
  const detail = useState(null);
  const preload = () => {
    const init = async () => {
      const { data } = await axios.post(`${HOST}/notice/detail`, { noticeId: match.params.noticeId }, httpHeader());
      detail.setState(data);
    };
    init();
  };

  useEffect(preload, []);

  return (
    <PageWrapper>
      <GoBack onClick={() => history.goBack()} />
      {detail.state ? (
        <Notice>
          <DetailBox>
            <DetailInnerBox>
              <DetailTitle>
                {language.state === "korean" && detail.state.title_kor}
                {language.state === "english" && detail.state.title_eng}
                {language.state === "chinese" && detail.state.title_chn}
                {language.state === "japanese" && detail.state.title_jap}
              </DetailTitle>
              <DetailCreatedAt>{dateConvertor(new Date(detail.state.createdAt))}</DetailCreatedAt>
              {language.state === "korean" && (
                <DetailContent dangerouslySetInnerHTML={{ __html: detail.state.content_kor }}></DetailContent>
              )}
              {language.state === "english" && (
                <DetailContent dangerouslySetInnerHTML={{ __html: detail.state.content_eng }}></DetailContent>
              )}
              {language.state === "chinese" && (
                <DetailContent dangerouslySetInnerHTML={{ __html: detail.state.content_chn }}></DetailContent>
              )}
              {language.state === "japanese" && (
                <DetailContent dangerouslySetInnerHTML={{ __html: detail.state.content_jap }}></DetailContent>
              )}
            </DetailInnerBox>
          </DetailBox>
        </Notice>
      ) : (
        <Loader />
      )}
    </PageWrapper>
  );
};

export default NoticePage;
