import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import { Alert } from "reactstrap";

import PageWrapper from "../../../Components/Common/PageWrapper";
import SmallButton from "../../../Components/Common/SmallButton";
import Loader from "../../../Components/Common/Loader";
import GoBack from "../../../Components/Common/GoBack";
import Input from "../../../Components/Common/Input";
import { languagePack } from "../../../Utils/language";
import globalContext from "../../../Hooks/globalContext";
import { HOST, AMOUNT_REGEX } from "../../../Utils/constants";
import useState from "../../../Hooks/useState";
import useInput from "../../../Hooks/useInput";
import { ToFixed } from "../../../Utils/util";
import Background from "../../../Resources/Images/background.png";
const InfoBox = styled.div`
  margin-top: 10px;
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  height: 120px;
  padding: 10px 15px;
  background-image: url(${Background});
  background-size: cover;
  color: white;
  font-size: 22px;
`;

const AbsoluteLabel = styled.div`
  position: absolute;
  left: 20px;
  top: 15px;
  font-size: 17px;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  width: 100%;
  :last-child {
    padding-top: 7.5px;
    border-bottom: 1px solid ${(props) => props.theme.darkGrey};
  }
`;

const InputLabel = styled.div`
  color: ${(props) => props.theme.darkGrey};
  font-size: 13px;
  margin: 5px 2.5px;
`;

const BottomInputWrapper = styled.div`
  display: flex;
`;

const MaxButton = styled.button`
  padding: 7.5px 15px;
  height: 100%;
  margin-left: 10px;
  width: 120px;
  background-color: ${(props) => props.theme.black};
  border: 0;
  border-radius: 2.5px;
  font-weight: bold;
  color: white;
  :focus {
    outline: none;
  }
  :hover {
    opacity: 0.75;
  }
  font-size: 14px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0px;
`;

const Page = ({ history }) => {
  const { language, user, httpHeader, modal } = useContext(globalContext);
  const balance = useState(null);
  const point = useState("");
  //   const toInput = useInput({ defaultValue: "", maxLength: 42 });
  const amountInput = useInput({ defaultValue: "", maxLength: 15, regex: AMOUNT_REGEX });
  const isLoading = useState(false);

  const getPoint = async () => {
    const { data } = await Axios.post(`${HOST}/user/point`, null, httpHeader());
    point.setState(data);
  };

  const maximumOnClick = () => {
    amountInput.setValue(point.state);
  };

  const preload = () => {
    getPoint();

    /** */
    modal.setState("현재 이용할 수 없는 기능입니다.");
    history.goBack();
  };

  const exchangeOnClick = async () => {
    if (amountInput.value === "" || Number(amountInput.value) === 0) {
      return modal.setState(languagePack("올바른 수량을 입력해주세요.", language.state));
    }

    if (Number(point.state) < Number(amountInput.value)) {
      return modal.setState(languagePack("올바른 수량을 입력해주세요.", language.state));
    }

    const requestBody = {
      amount: Number(amountInput.value),
    };
    const { data } = await Axios.post(`${HOST}/user/point/exchange`, requestBody, httpHeader());

    if (data.status) {
      modal.setState(languagePack("성공적으로 처리되었습니다.", language.state));
      getPoint();
    } else {
      modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
    }
    amountInput.setValue("");
  };

  const goBackOnClick = () => history.goBack();

  useEffect(preload, []);

  return (
    <PageWrapper>
      <GoBack onClick={goBackOnClick} />
      {user.state && (
        <Wrapper>
          <InputWrapper>
            <InfoBox>
              <AbsoluteLabel>Point</AbsoluteLabel>
              {point.state === "" ? <Loader fit={true} size={15} /> : `${Number(point.state)} P`}
            </InfoBox>
            {/* <Input {...toInput} placeholder={languagePack("받을사람 주소", language.state)} /> */}
          </InputWrapper>
          <Alert style={{ width: "100%", margin: 0, marginTop: 10, fontSize: 13 }} color="info">
            포인트를 GIP 코인으로 전환 신청합니다.
          </Alert>
          <InputWrapper style={{ paddingTop: 5 }}>
            <BottomInputWrapper>
              <Input {...amountInput} placeholder={languagePack("신청할 포인트", language.state)} />
              <MaxButton onClick={maximumOnClick}>{languagePack("최대", language.state)}</MaxButton>
            </BottomInputWrapper>
          </InputWrapper>
          <ButtonWrapper>
            <SmallButton
              onClick={exchangeOnClick}
              disabled={isLoading.state}
              style={{ padding: "10px 30px" }}
              text={languagePack("포인트 전환", language.state)}
            />
          </ButtonWrapper>
        </Wrapper>
      )}
    </PageWrapper>
  );
};

export default Page;
