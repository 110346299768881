import React from "react";
import styled from "styled-components";

import Theme from "../../Styles/Theme";
import { LeftArrowIcon } from "../Icons";

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
const Container = ({ onClick }) => {
  return (
    <IconWrapper onClick={onClick}>
      <LeftArrowIcon color={"white"} size={20} />
    </IconWrapper>
  );
};

export default Container;
