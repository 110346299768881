import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import { Helmet } from "react-helmet";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Label } from "reactstrap";

import LogoImage from "../../Resources/Images/logo_main.png";
import GlobalContext from "../../Hooks/globalContext";
import { HOST, NUMBER_REGEX } from "../../Utils/constants";
import { languagePack } from "../../Utils/language";
import useState from "../../Hooks/useState";
import useInput from "../../Hooks/useInput";
import Input from "../../Components/Common/Input";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
`;

const AuthWrapper = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 10% 0px 10%;

  input {
    margin-bottom: 15px;
  }
  button {
    margin: 5px 0px;
  }
`;

const LinkWrapper = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 13px;
  text-align: center;
`;

const LinkSpan = styled(Link)`
  color: ${(props) => props.theme.darkGrey};
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  :hover {
    text-decoration: underline;
    color: grey;
  }
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const LengthWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const AuthButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.mainColor};
  font-size: 14px;
  color: white;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  :hover {
    opacity: 0.75;
  }
  :focus {
    outline: none;
  }
  cursor: pointer;
`;

/** Container  */

const Container = ({ history, page, match }) => {
  const { language, modal } = useContext(GlobalContext);
  const ended = useState(false);
  const emailInput = useInput({ defaultValue: "" });
  const passwordInput = useInput({ defaultValue: "" });

  const AuthOnSubmit = (e) => {
    e.preventDefault();

    const regsiterParams = {
      email: emailInput.value,
      password: passwordInput.value,
    };
    Axios.post(`${HOST}/register/invite`, regsiterParams)
      .then((response) => {
        if (response.data.status) {
          ended.setState(true);
          modal.setState(languagePack("회원가입이 완료되었습니다.", language.state));
        } else {
          switch (response.data.code) {
            case "pin":
              modal.setState(languagePack("잘못된 핀번호입니다.", language.state));
              break;
            case "duplicate":
              modal.setState(languagePack("중복된 ID 입니다.", language.state));
              break;
            case "server":
              modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
              break;
            case "invalid recommend":
              modal.setState(languagePack("존재하지 않는 추천인입니다.", language.state));
              break;
            default:
              modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
              break;
          }
          return;
        }
      })
      .catch((error) => {
        modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
      });
  };

  const preload = () => {
    if (match.params.code) {
      emailInput.setValue(atob(match.params.code));
    }
  };

  useEffect(preload, []);

  return (
    <>
      <Wrapper>
        {ended.state ? (
          <AuthWrapper style={{ justifyContent: "center", alignItems: "center" }}>
            <div style={{ color: "white", padding: 20, display: "flex", justifyContent: "center" }}>
              {languagePack("회원가입이 완료되었습니다.", language.state)}
            </div>
            <AuthButton
              onClick={() => window.open(`https://play.google.com/store/apps/details?id=app.dp.wallet`)}
              type="button"
            >
              {languagePack("안드로이드 앱 다운로드", language.state)}
            </AuthButton>
          </AuthWrapper>
        ) : (
          <AuthWrapper onSubmit={AuthOnSubmit}>
            <LengthWrapper style={{ paddingTop: 60 }}>
              {/* <TitleSpan>Register</TitleSpan> */}
              <Input readOnly={true} placeholder="* E-Mail" type="email" {...emailInput} />
              <Input placeholder="* Password (비밀번호)" type="password" {...passwordInput} />
              <AuthButton>{languagePack("확인", language.state)}</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/">{languagePack("돌아가기", language.state)}</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          </AuthWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default Container;
