import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
    ${reset};
    *{
        @import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,700&display=swap&subset=korean");
        box-sizing:border-box;
    }
    *{
        box-sizing:border-box;
    }
    html {
    height: 100%;
    width:100%;
    }
    body {
    line-height:1.5;
    width:100%;
    height: 100%;
    font-family: 'Noto Sans KR', sans-serif;
    }
    #root {
        width:100%;
    height: 100%;
}
`;
