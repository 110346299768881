import React from "react";
import styled from "styled-components";

import PageWrapper from "../../Components/Common/PageWrapper";
import ErcHistory from "./ErcHistory";
import EthHistory from "./EthHistory";
import PointHistory from "./PointHistory";
import useState from "../../Hooks/useState";
import Background from "../../Resources/Images/background.png";

const SelectBox = styled.div`
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectItem = styled.div`
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background-image: url(${Background});
  background-size: cover;
  display: flex;
  align-items: flex-end;
  color: white;
  font-size: 2.25em;
  font-weight: bold;
  padding: 20px;
  :hover {
    opacity: 0.75;
    cursor: pointer;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
`;

const Container = () => {
  const phase = useState(null);

  const phaseOnClick = (type) => {
    phase.setState(type);
  };

  return (
    <PageWrapper contentStyle={{ paddingTop: phase.state ? 0 : 20 }}>
      {phase.state === null && (
        <SelectBox>
          <SelectItem onClick={phaseOnClick.bind(null, "eth")}>Ethereum</SelectItem>
          <SelectItem onClick={phaseOnClick.bind(null, "erc-20")}>G I P</SelectItem>
          <SelectItem onClick={phaseOnClick.bind(null, "point")}>G I P K</SelectItem>
        </SelectBox>
      )}
      {phase.state === "eth" && <EthHistory phase={phase} />}
      {phase.state === "erc-20" && <ErcHistory phase={phase} />}
      {phase.state === "point" && <PointHistory phase={phase} />}
    </PageWrapper>
  );
};

export default Container;
