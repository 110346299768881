import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";

import PageWrapper from "../../../Components/Common/PageWrapper";
import GoBack from "../../../Components/Common/GoBack";
import globalContext from "../../../Hooks/globalContext";
import useState from "../../../Hooks/useState";
import { CONTRACT_ADDRESS, HOST } from "../../../Utils/constants";
import { languagePack } from "../../../Utils/language";
import Theme from "../../../Styles/Theme";
import { dateConvertor, numberWithCommas, ToFixed } from "../../../Utils/util";
import Loader from "../../../Components/Common/Loader";
import { LeftArrowIcon } from "../../../Components/Icons";
import DefaultButton from "../../../Components/Common/DefaultButton";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  overflow: auto;
`;

const ModalTable = styled.table`
  margin: 5px 0px;
  font-size: 12px;
  th {
    padding: 10px 0px;
  }
  td {
    padding: 10px 0px 10px 10px;
    word-break: break-all;
    display: flex;
    align-items: center;
  }
  tr {
    :not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.darkGrey};
    }
  }
`;

const RowWrapper = styled.div`
  height: 65px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.lightGrey};
  align-items: center;
  justify-content: flex-end;
  padding: 7.5px 25px;
  background-color: white;

  cursor: pointer;
  :hover {
    opacity: 0.75;
    background-color: ${Theme.lightGrey};
  }
`;

const RowTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const RowBottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const DateText = styled.span`
  font-size: 13px;
  color: ${(props) => props.theme.darkGrey};
`;

const AmountWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const AmountText = styled.span`
  margin-right: 2.5px;
`;

const RowTitle = styled.span`
  font-size: 16px;
`;

const NoneTitleView = styled.div`
  padding: 20px;
  text-align: center;
`;

const NoneTitle = styled.span`
  color: ${(props) => props.theme.darkGrey};
  font-size: 15px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const ButtonWrapper = styled.div`
  padding: 10px 20px;
  width: 100%;
`;

const ModalTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.div`
  font-size: 22px;
  margin-bottom: 15px;
`;

const Container = ({ phase }) => {
  const stakingList = useState(false);
  const { httpHeader, language, modal } = useContext(globalContext);
  const disabled = useState(true);
  const isLoading = useState(false);

  const getStaking = async () => {
    const { data } = await Axios.post(`${HOST}/user/staking/list`, null, httpHeader());
    stakingList.setState(data);
  };

  const preload = () => {
    getStaking();
  };

  const rewardOnClick = async () => {
    isLoading.setState(true);
    const { data } = await Axios.post(`${HOST}/user/staking/reward`, null, httpHeader());
    if (data.status) {
      getStaking();

      setTimeout(() => {
        disabled.setState(true);
        modal.setState(data.result.me + " " + languagePack("포인트가 지급되었습니다.", language.state));
        isLoading.setState(false);
      }, 500);
    } else {
      isLoading.setState(false);
      switch (data.code) {
        case "none":
          modal.setState(languagePack("수령 가능한 보상이 없습니다.", language.state));
          break;
        case "server":
          modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
          break;
        default:
          modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
          break;
      }
    }
  };

  useEffect(preload, []);
  const Row = ({ item }) => {
    if (item.available_cycle > 0 && item.status === 0) {
      disabled.setState(false);
    }

    const onClick = () => {
      modal.setState(
        <ModalTableWrapper>
          <ModalTitle>{languagePack("스테이킹 상세", language.state)}</ModalTitle>
          <ModalTable>
            <tbody>
              <tr>
                <th>{languagePack("스테이킹 총량", language.state)}</th>
                <td>
                  {Number(item.staking_amount)} {CONTRACT_ADDRESS[0].name.toUpperCase()}
                </td>
              </tr>
              <tr>
                <th>{languagePack("스테이킹 사이클", language.state)}</th>
                <td>
                  {item.paid_cycle}/{Number(item.maximum_cycle)}
                </td>
              </tr>
              <tr>
                <th>{languagePack("1 사이클당 보상", language.state)}</th>
                <td>{numberWithCommas(ToFixed(Number(item.unit) * Number(item.staking_per / 100)))} Point</td>
              </tr>
              <tr>
                <th>{languagePack("수령 가능 보상", language.state)}</th>
                <td>{item.status === 1 ? 0 : ToFixed(item.my_reward)} Point</td>
              </tr>
              <tr>
                <th>{languagePack("수령한 보상", language.state)}</th>
                <td>{ToFixed(Number(item.received_reward))} Point</td>
              </tr>
              {/* <tr>
                <th>To</th>
                <td>{item.to}</td>
              </tr>
              <tr>
                <th>Date</th>
                <td>{dateConvertor(timeStamp)}</td>
              </tr> */}
            </tbody>
          </ModalTable>
        </ModalTableWrapper>
      );
    };

    return (
      <RowWrapper onClick={onClick}>
        <RowTop>
          <RowTitle>
            #{item.id} {languagePack("스테이킹 상태", language.state)}
          </RowTitle>
          {item.status === 0 && item.available_cycle === 0 && (
            <DateText style={{ color: Theme.orange }}>{languagePack("스테이킹 중", language.state)}</DateText>
          )}
          {item.status === 0 && Number(item.available_cycle) > 0 && (
            <DateText style={{ color: Theme.mainColor }}>{languagePack("보상 수령 가능", language.state)}</DateText>
          )}
          {item.status === 1 && <DateText>{languagePack("지급 됨", language.state)}</DateText>}
        </RowTop>
        <RowBottom>
          <DateText>
            {dateConvertor(new Date(item.created_at), "summary")} ~ {dateConvertor(new Date(item.release_time), "hour")}
          </DateText>
          <AmountWrapper>
            <AmountText>{numberWithCommas(ToFixed(item.staking_amount))}</AmountText>
            {/* <img src={TokenIcon} style={{ height: 15 }} /> */}
          </AmountWrapper>
        </RowBottom>
      </RowWrapper>
    );
  };

  return (
    <PageWrapper>
      <GoBack onClick={() => phase.setState(0)} />
      <Wrapper>
        {stakingList.state ? (
          <>
            {stakingList.state.map((item, index) => {
              return <Row key={index} item={item} />;
            })}
            {stakingList.state && stakingList.state.length === 0 && (
              <NoneTitleView>
                <NoneTitle>{languagePack("기록이 없습니다.", language.state)}</NoneTitle>
              </NoneTitleView>
            )}
          </>
        ) : (
          <Loader size={15} color="black" />
        )}
      </Wrapper>
      <ButtonWrapper>
        <DefaultButton
          disabled={isLoading.state}
          onClick={rewardOnClick}
          text={languagePack("모두 받기", language.state)}
          grey={disabled.state}
        />
      </ButtonWrapper>
    </PageWrapper>
  );
};

export default Container;
