import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import ReactQuill from "react-quill";
import axios from "axios";
import { toast } from "react-toastify";

import GlobalContext from "../../../Hooks/globalContext";
import "../../../Styles/writeForm.scss";
import useState from "../../../Hooks/useState";
import DefaultButton from "../../Common/DefaultButton";
import useInput from "../../../Hooks/useInput";
import Input from "../../Common/Input";
import { HOST } from "../../../Utils/constants";

const Top = styled.div`
  height: calc(100% - 60px);
  width: 100%;
`;

const Bottom = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
`;

const BottomItem = styled.div``;

const Page = ({ adminPage, type = "create", focus }) => {
  const { httpHeader, modal } = useContext(GlobalContext);
  const titleInputKor = useInput({ defaultValue: type === "create" ? "" : focus.state.title_kor, maxLength: 100 });
  const editorHtmlKor = useState(type === "create" ? null : focus.state.content_kor);
  const langType = useState("kor");
  const preload = () => {};
  const writeOnChange = (html) => {
    switch (langType.state) {
      case "kor":
        editorHtmlKor.setState(html);
        break;
    }
  };

  const writeOnClick = async () => {
    if (titleInputKor.value.length < 2) {
      modal.setState("(한국어)제목이 짧습니다.");
      return;
    }

    const requestBody = {
      title_kor: titleInputKor.value,
      content_kor: editorHtmlKor.state,
      action: type,
      id: type === "create" ? null : focus.state.id,
    };

    const { data } = await axios.post(`${HOST}/admin/notice/crud`, requestBody, httpHeader());

    if (data.status) {
      modal.setState("성공적으로 처리되었습니다.");
      adminPage.setState("noticeList");
    } else {
      modal.setState(`오류 : ${data.code}`);
    }
  };

  const selectOnChange = ({ target: { value } }) => {
    langType.setState(value);
  };

  useEffect(preload, []);
  return (
    <>
      <Top>
        {langType.state === "kor" && <Input {...titleInputKor} placeholder="제목" style={customInput} />}
        {langType.state === "kor" && (
          <ReactQuill
            theme="snow"
            onChange={writeOnChange}
            value={editorHtmlKor.state}
            modules={EDITOR_MODULES}
            formats={EDITOR_FORMATS}
            bounds={".app"}
            placeholder={""}
          />
        )}
      </Top>
      <Bottom>
        <BottomItem></BottomItem>
        <BottomItem></BottomItem>
        <BottomItem>
          <DefaultButton text="작성" onClick={writeOnClick} />
        </BottomItem>
      </Bottom>
    </>
  );
};

export default Page;

const EDITOR_FORMATS = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
const EDITOR_MODULES = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "image"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const customInput = {
  width: "100%",
  border: "1px solid #ccc",
  padding: "5px 10px",
  marginBottom: "5px",
  borderRadius: "4px",
  outline: "none",
  backgroundColor: "white",
  color: "black",
};
