import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";
import Axios from "axios";
import { Alert } from "reactstrap";

// import Logo_Main from "../../Resources/Images/coin_main.png";

import PageWrapper from "../../Components/Common/PageWrapper";
import TokenSelect from "../../Components/Common/TokenSelect";
import useState from "../../Hooks/useState";
import useInput from "../../Hooks/useInput";
import { ChainIcon, EthereumIcon, RightArrow, RightArrowIcon } from "../../Components/Icons";
import _Input from "../../Components/Common/Input";
import SmallButton from "../../Components/Common/SmallButton";
import DefaultButton from "../../Components/Common/DefaultButton";
import GoBack from "../../Components/Common/GoBack";
import globalContext from "../../Hooks/globalContext";
import { languagePack } from "../../Utils/language";
import { AMOUNT_REGEX, HOST, GAS_PRICE_API, CONTRACT_ADDRESS } from "../../Utils/constants";
import Theme from "../../Styles/Theme";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputWrapper = styled.div`
  padding: 0px 11px;
  width: 100%;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
`;

const Input = styled(_Input)`
  font-size: 15px;
  border-radius: 0px;
  :read-only {
    opacity: 0.8;
  }
`;

const IconWrapper = styled.div`
  margin: 7.5px 0px;
`;

const DetailBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const DetailRow = styled.div`
  display: flex;
  color: white;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  span {
    line-height: 0px;
  }
  :nth-child(2n) {
    margin-bottom: 10px;
  }
`;

const Return = styled.div`
  font-size: 16px;
  padding: 2.5px 10px;
  font-weight: bold;
  color: ${(props) => props.theme.darkGrey};

  cursor: pointer;
  :hover {
    text-decoration: underline;
    opacity: 0.8;
  }
`;

const Container = ({ history }) => {
  const { language, modal, httpHeader, balance, getBalance, sendEth, sendToken, user } = useContext(globalContext);
  const fromSelected = useState(null);
  const toSelected = useState(null);

  const fromType = useState("all");
  const toType = useState("all");

  const fromInput = useInput({ defaultValue: "", regex: AMOUNT_REGEX });
  const toInput = useInput({ defaultValue: "" });
  const marketcap = useState(null);
  const config = useState(null);
  const isLoading = useState(false);
  const txLoading = useState(false);

  const admin = useState(null);

  const disabled = useState(false);

  const selectInit = (type) => {
    if (fromSelected.state) {
      if (fromSelected.state === "eth") {
        toType.setState("erc");
      } else {
        toType.setState("eth");
      }
    }
  };

  const calculateOnClick = async () => {
    if (!fromSelected.state || !toSelected.state || fromInput.value === "") {
      return modal.setState(languagePack("모든 항목을 입력해주세요.", language.state));
    }
    if (isNaN(Number(fromInput.value))) {
      return modal.setState(languagePack("모든 항목을 입력해주세요.", language.state));
    }
    isLoading.setState(true);
    const { data } = await Axios.post(`${HOST}/api/marketcap`, {}, httpHeader());
    marketcap.setState(data);
    isLoading.setState(false);

    const {
      data: { id: configId, percent, admin_address },
    } = await Axios.post(`${HOST}/api/config`, { column: [`id`, `admin_address`] }, httpHeader());
    admin.setState(admin_address);
    config.setState(configId);

    const from = Number(data[`price_${fromSelected.state}`]);
    const to = Number(data[`price_${toSelected.state}`]);
    const fromPrice = from * Number(fromInput.value);
    const toAmount = fromPrice / to;
    // const toCalculate = toAmount * Number(`${(100 - Number(percent)) / 100}`);
    toInput.setValue(Number(toAmount).toFixed(8));
    await getBalance(fromSelected.state);
    disabled.setState(true);
  };

  const swapOnClick = async () => {
    const confirm = async (hash) => {
      const confirmForm = {
        configId: config.state,
        priceId: marketcap.state.id,
        fromAmount: fromInput.value,
        fromType: fromSelected.state,
        toAmount: toInput.value,
        toType: toSelected.state,
        hash,
      };

      const { data } = await Axios.post(`${HOST}/user/swap`, confirmForm, httpHeader());
      resetOnClick();
      modal.setState(languagePack("성공적으로 처리되었습니다.", language.state));
    };

    const reject = () => {
      txLoading.setState(false);
      modal.setState(languagePack("전송 오류입니다.", language.state));
    };
    txLoading.setState(true);

    const { data } = await Axios.post(GAS_PRICE_API);

    const sendForm = {
      address: user.state.wallet_address,
      fee: data.fast,
      to: admin.state,
      amount: fromInput.value,
      isLoading,
      confirm,
      reject,
      symbol: fromSelected.state,
    };

    switch (fromSelected.state) {
      case "eth":
        await sendEth(sendForm);
        return;
      default:
        await sendToken(sendForm);
        return;
    }
  };

  const resetOnClick = () => {
    fromInput.setValue("");
    toInput.setValue("");
    marketcap.setState(null);
    txLoading.setState(false);
    config.setState(null);
    disabled.setState(false);
    admin.setState(null);
  };

  useEffect(() => {
    selectInit();
  }, [fromSelected.state]);

  return (
    <PageWrapper>
      <GoBack onClick={() => history.goBack()} />
      <Wrapper>
        <TokenSelect
          disabled={disabled.state}
          type={fromType.state}
          placeholder={`From Asset`}
          selected={fromSelected}
        />
        {fromSelected.state && (
          <>
            <InputWrapper>
              <Input readOnly={disabled.state} {...fromInput} placeholder={"From Amount"} />
            </InputWrapper>
            <IconWrapper>
              <ChainIcon color="grey" />
            </IconWrapper>

            <TokenSelect disabled={disabled.state} type={toType.state} placeholder={`To Asset`} selected={toSelected} />
            {marketcap.state ? (
              <>
                <InputWrapper>
                  <Input readOnly={disabled.state} {...toInput} placeholder={"To Amount"} />
                </InputWrapper>
                <div style={{ width: "100%", padding: "0px 10px", marginTop: 10 }}>
                  <DetailBox>
                    {/* <Detail logo={true} title="From Asset" content={fromSelected.state} />
                    <Detail
                      title={`1 ${fromSelected.state}`}
                      content={`${Number(`${marketcap.state[`price_${fromSelected.state}`]}`).toFixed(4)}$`}
                    />
                    <Detail logo={true} title="To Asset" content={toSelected.state} />
                    <Detail
                      title={`1 ${toSelected.state}`}
                      content={`${Number(`${marketcap.state[`price_${toSelected.state}`]}`).toFixed(4)}$`}
                    /> */}
                    <DetailRow style={{ fontSize: 16 }}>
                      <div>
                        <span style={{ marginRight: 5 }}>{Number(Number(fromInput.value).toFixed(8))}</span>
                        {/* {fromSelected.state === CONTRACT_ADDRESS[0].name && (
                          <img src={Logo_Main} style={{ width: 13, height: 13 }} />
                        )} */}
                        {fromSelected.state === "eth" && <EthereumIcon size={13} />}
                        <span style={{ marginLeft: 2, marginRight: 2 }}>{fromSelected.state}</span>
                      </div>
                      <RightArrow size={10}></RightArrow>
                      <div>
                        <span style={{ marginRight: 5 }}>{Number(Number(toInput.value).toFixed(8))}</span>
                        {/* {toSelected.state === CONTRACT_ADDRESS[0].name && (
                          <img src={Logo_Main} style={{ width: 13, height: 13 }} />
                        )} */}
                        {toSelected.state === "eth" && <EthereumIcon size={13} />}
                        <span style={{ marginLeft: 2, marginRight: 2 }}>{toSelected.state}</span>
                      </div>
                    </DetailRow>
                  </DetailBox>
                  {balance.state[fromSelected.state] >= 0 ? (
                    <>
                      {Number(balance.state[fromSelected.state]) < Number(fromInput.value) ? (
                        <Alert color="danger">{languagePack("잔액이 부족합니다.", language.state)}</Alert>
                      ) : (
                        <DefaultButton
                          disabled={txLoading.state}
                          onClick={swapOnClick}
                          text={languagePack("스왑 신청", language.state)}
                        />
                      )}
                      <LoaderWrapper>
                        <Return onClick={resetOnClick}>{languagePack("초기화", language.state)}</Return>
                      </LoaderWrapper>
                    </>
                  ) : (
                    <LoaderWrapper>
                      <ClipLoader size={15} color={Theme.mainColor} />
                    </LoaderWrapper>
                  )}
                </div>
              </>
            ) : (
              <SmallButton
                disabled={isLoading.state}
                onClick={calculateOnClick}
                style={{ marginTop: 5 }}
                text={languagePack("스왑 계산", language.state)}
              />
            )}
          </>
        )}
      </Wrapper>
    </PageWrapper>
  );
};

export default Container;
