const ETHERSCAN_API_KEY = "7VB9ZV6EVR6EHJJA88SPTNXSCD9CHRBQ8K";
const INFURA_API_KEY = "8ae75c454658453a9e1728e2a9a2e288";

export const HOST = "https://dpwallet.co.kr:5413";
export const CLIENT_HOST = "https://dpwallet.co.kr";
export const NODE_ENDPOINT = `https://mainnet.infura.io/v3/${INFURA_API_KEY}`;
export const CHAIN_NAME = "mainnet";
export const ETHERSCAN_TX_URL = `https://etherscan.io/tx/`;
export const CHAIN_ID = 1;

export const CONTRACT_ADDRESS = [];

export const TOKEN_INFO = {};

export const ETHERSCAN_API_ENDPOINT = ({ address, page, offset, sort }) =>
  `https://api.etherscan.io/api?module=account&action=tokentx&address=${address}&page=${page}&offset=${offset}&sort=${sort}&apikey=${ETHERSCAN_API_KEY}`;
export const ETHERSCAN_ADDRESS_URL = `https://etherscan.io/address/`;

export const TOKEN_ENDPOINT = (address) => `https://etherscan.io/token/${address}`;

export const ETHERSCAN_API_ENDPOINT_ETH = ({ address, page, offset, sort }) =>
  `https://api.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=${sort}&page=${page}&offset=${offset}&apikey=${ETHERSCAN_API_KEY}`;

/***********
 *
 *
//  */

// export const ETHERSCAN_API_ENDPOINT_ETH = ({ address, page, offset, sort }) =>
//   `https://api-ropsten.etherscan.io/api?module=account&action=txlist&address=${address}&startblock=0&endblock=99999999&sort=${sort}&page=${page}&offset=${offset}&apikey=${ETHERSCAN_API_KEY}`;

// export const ETHERSCAN_API_ENDPOINT = ({ address, page, offset, sort }) =>
//   `https://api-ropsten.etherscan.io/api?module=account&action=tokentx&address=${address}&page=${page}&offset=${offset}&sort=${sort}&apikey=${ETHERSCAN_API_KEY}`;

// export const CONTRACT_ADDRESS = [];

// export const TOKEN_INFO = {};

// export const ETHERSCAN_ADDRESS_URL = `https://ropsten.etherscan.io/address/`;

// export const HOST = "http://localhost:5413";
// export const CLIENT_HOST = "http://localhost:3000";
// export const CHAIN_ID = 3;
// export const NODE_ENDPOINT = `https://ropsten.infura.io/v3/${INFURA_API_KEY}`;
// export const CHAIN_NAME = "ropsten";
// export const ETHERSCAN_TX_URL = `https://ropsten.etherscan.io/tx/`;
// export const TOKEN_ENDPOINT = (address) => `https://ropsten.etherscan.io/token/${address}`;

export const GAS_PRICE_API = "https://ethgasstation.info/json/ethgasAPI.json";
export const PAGE_UNIT = 5;
export const NOTICE_PAGE_UNIT = 10;
export const DONATION_LIST_PAGE_UNIT = 10;
export const ADMIN_PAGE_UNIT = 15;
export const ADMIN_EXCHANGE_UNIT = 10;
export const SECOND = 1000;
export const MINUTE = 60;
export const VIEWPORT_MAX_WIDTH = 450;

export const AMOUNT_REGEX = /^[]?\d*(\.?\d*)$/;
export const NUMBER_REGEX = /^[0-9]+$/;
