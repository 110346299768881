import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";

import PageWrapper from "../../../Components/Common/PageWrapper";

import { HOST, ETHERSCAN_API_ENDPOINT, PAGE_UNIT, ETHERSCAN_API_ENDPOINT_ETH } from "../../../Utils/constants";
import useState from "../../../Hooks/useState";
import globalContext from "../../../Hooks/globalContext";
import Loader from "../../../Components/Common/Loader";
import { languagePack } from "../../../Utils/language";
import { DownloadIcon, LeftArrowIcon, UploadIcon } from "../../../Components/Icons";
import { numberWithCommas, dateConvertor, ToFixed } from "../../../Utils/util";
import Theme from "../../../Styles/Theme";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px;
`;

const EmptyLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 25px;
  color: ${(props) => props.theme.darkGrey};
`;

const Item = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  height: 60px;
  :not(:last-child) {
    margin-bottom: 10px;
  }
`;
const WalletInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  width: 70%;
`;

const IconFixer = styled.div`
  position: absolute;
`;

const AmountInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SummaryWallet = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ModalTable = styled.table`
  margin: 5px 0px;
  font-size: 12px;
  th {
    padding: 10px 0px;
  }
  td {
    padding: 10px 0px 10px 10px;
    word-break: break-all;
    display: flex;
    align-items: center;
  }
  tr {
    :not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.darkGrey};
    }
  }
`;

const MoreButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
`;

const MoreLabel = styled.div`
  color: ${(props) => props.theme.darkGrey};
  padding: 2.5px 5px;
`;

const ModalTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled.div`
  font-size: 22px;
  margin-bottom: 15px;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 15px;
  top: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const Row = ({ item, user, web3, modal, language }) => {
  const timeStamp = new Date(Number(`${item.timeStamp}000`));
  const onClick = () => {
    modal.setState(
      <ModalTableWrapper>
        <ModalTitle>Trnasaction Receipt</ModalTitle>
        <ModalTable>
          <tbody>
            <tr>
              <th>BlockNumber</th>
              <td>#{item.blockNumber}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{numberWithCommas(ToFixed(web3.utils.fromWei(item.value)))}</td>
            </tr>
            <tr>
              <th>Hash</th>
              <td>{item.hash}</td>
            </tr>
            <tr>
              <th>From</th>
              <td>{item.from}</td>
            </tr>
            <tr>
              <th>To</th>
              <td>{item.to}</td>
            </tr>
            <tr>
              <th>Date</th>
              <td>{dateConvertor(timeStamp)}</td>
            </tr>
          </tbody>
        </ModalTable>
      </ModalTableWrapper>
    );
  };
  return item.from === user.wallet_address.toLowerCase() ? (
    <Item onClick={onClick}>
      <IconFixer>
        <UploadIcon size={36} />
      </IconFixer>
      <WalletInfo>
        <span style={{ fontWeight: "bold" }}>Sent</span>
        <SummaryWallet style={{ color: Theme.darkGrey, fontSize: 12 }}>To : {item.to}</SummaryWallet>
      </WalletInfo>
      <AmountInfo>
        {item.value === "0" ? (
          languagePack("컨트랙트 호출", language.state)
        ) : (
          <>
            <div style={{ marginRight: 5 }}>-{numberWithCommas(ToFixed(web3.utils.fromWei(item.value)))}</div>
            <div>ETH</div>
          </>
        )}
      </AmountInfo>
    </Item>
  ) : (
    <Item onClick={onClick}>
      <IconFixer>
        <DownloadIcon size={36} />
      </IconFixer>
      <WalletInfo>
        <span style={{ fontWeight: "bold" }}>Received</span>
        <SummaryWallet style={{ color: Theme.darkGrey, fontSize: 12 }}>From : {item.from}</SummaryWallet>
      </WalletInfo>
      <AmountInfo>
        {item.value === "0" ? (
          languagePack("컨트랙트 호출", language.state)
        ) : (
          <>
            <div style={{ marginRight: 5, color: "#5EC866" }}>
              +{numberWithCommas(ToFixed(web3.utils.fromWei(item.value)))}
            </div>
            <div style={{ color: "#5EC866" }}>ETH</div>
          </>
        )}
      </AmountInfo>
    </Item>
  );
};
{
  /* <SpaceBetween>
        <BlockNumber>
          <CubeIcon size={10} color={"white"} />
          <span>{item.blockNumber}</span>
        </BlockNumber>
        {item.from === user.wallet_address.toLowerCase() ? <Out>OUT</Out> : <In>IN</In>}
      </SpaceBetween>
      <SpaceBetween>
        <Hash>{item.hash}</Hash>
      </SpaceBetween>
      <SpaceBetween>
        <Left>
          <tbody>
            <tr>
              <th>From</th>
              <td>{item.from}</td>
            </tr>
            <tr>
              <th>To</th>
              <td>{item.to}</td>
            </tr>
          </tbody>
        </Left>
      </SpaceBetween>
      <SpaceBetween>
        <div />
        <Right>
          <span>
            {item.value === "0" ? (
              languagePack("컨트랙트 호출", language.state)
            ) : (
              <EthSpan>
                {`${web3.utils.fromWei(item.value)} ETH`} <EthereumIcon size={14} />
              </EthSpan>
            )}
          </span>
        </Right>
      </SpaceBetween> */
}

const Page = ({ phase }) => {
  const { getTxCount, user, getUser, language, web3, modal } = useContext(globalContext);
  const page = useState(1);
  const list = useState(null);
  const isLoading = useState(false);
  const isEnd = useState(false);

  const moreOnClick = async () => {
    if (isLoading.state) {
      return;
    }
    isLoading.setState(true);
    const _page = page.state;
    page.setState(page.state + 1);
    const { data } = await Axios.post(
      `${ETHERSCAN_API_ENDPOINT_ETH({
        address: user.state.wallet_address,
        page: _page + 1,
        offset: PAGE_UNIT,
        sort: "desc",
      })}`
    );
    if (data.status === "0") {
      isLoading.setState(false);
      isEnd.setState(true);
      return;
    }
    const concat = list.state.concat(data.result);
    list.setState(concat);
    isLoading.setState(false);
  };

  const preload = async () => {
    if (user.state) {
      // const count = await getTxCount(user.state.wallet_address);
      const { data } = await Axios.post(
        `${ETHERSCAN_API_ENDPOINT_ETH({
          address: user.state.wallet_address,
          page: page.state,
          offset: PAGE_UNIT,
          sort: "desc",
        })}`
      );
      list.setState(data.result);
    }
  };

  useEffect(() => {
    preload();
  }, [user.state]);

  return (
    <Wrapper>
      <IconWrapper onClick={() => phase.setState(null)}>
        <LeftArrowIcon color={`white`} size={20} />
      </IconWrapper>
      {list.state ? (
        list.state.length === 0 ? (
          <EmptyLabel>{languagePack("내역이 없습니다.", language.state)}</EmptyLabel>
        ) : (
          <>
            {list.state.map((item, index) => {
              return <Row item={item} user={user.state} key={index} web3={web3} modal={modal} language={language} />;
            })}
            <MoreButton>
              {isLoading.state ? (
                <Loader fit={true} size={15} color={Theme.darkGrey} />
              ) : (
                !isEnd.state && <MoreLabel onClick={moreOnClick}>{languagePack("더 보기", language.state)}</MoreLabel>
              )}
            </MoreButton>
          </>
        )
      ) : (
        <Loader color={Theme.darkGrey} size={15} />
      )}
    </Wrapper>
  );
};

export default Page;
