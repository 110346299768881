import React from "react";
import styled from "styled-components";
import Select from "react-select";

import customStyles from "../../Styles/SelectStyle";
import { EthereumIcon } from "../Icons";
import { CONTRACT_ADDRESS } from "../../Utils/constants";

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OptionSpan = styled.span`
  margin-left: 5px;
`;

const SelectWrapper = styled.div`
  padding: 5px 10px;
  width: 100%;
`;

const Container = ({ disabled = false, selected, style, type = "all", placeholder = "Choose asset" }) => {
  const handleChange = ({ value }) => {
    selected.setState(value);
  };

  return (
    <SelectWrapper style={style}>
      {type === "all" && (
        <Select
          isDisabled={disabled}
          onChange={handleChange}
          isClearable={false}
          isSearchable={false}
          styles={customStyles}
          placeholder={placeholder}
          options={[
            {
              value: "eth",
              label: (
                <OptionWrapper>
                  <EthereumIcon size={18} />
                  <OptionSpan>ETH (Ethereum)</OptionSpan>
                </OptionWrapper>
              ),
            },
            ...CONTRACT_ADDRESS.map(({ name, fullName, symbol }, index) => {
              return {
                key: index,
                value: name,
                label: (
                  <OptionWrapper>
                    <img src={symbol} style={{ width: 16, height: 16 }} />
                    <OptionSpan>
                      {name.toUpperCase()} ({fullName})
                    </OptionSpan>
                  </OptionWrapper>
                ),
              };
            }),
            {
              value: "point",
              label: (
                <OptionWrapper>
                  <img src={CONTRACT_ADDRESS[0].symbol} style={{ width: 16, height: 16 }} />
                  <OptionSpan>GIPK</OptionSpan>
                </OptionWrapper>
              ),
            },
          ]}
        />
      )}
      {type === "available" && (
        <Select
          isDisabled={disabled}
          onChange={handleChange}
          isClearable={false}
          isSearchable={false}
          styles={customStyles}
          placeholder={placeholder}
          options={availableOptions}
        />
      )}
      {type === "erc" && (
        <Select
          isDisabled={disabled}
          onChange={handleChange}
          isClearable={false}
          isSearchable={false}
          styles={customStyles}
          placeholder={placeholder}
          options={ercOptions}
        />
      )}
      {type === "eth" && (
        <Select
          isDisabled={disabled}
          onChange={handleChange}
          isClearable={false}
          isSearchable={false}
          styles={customStyles}
          placeholder={placeholder}
          options={ethOptions}
        />
      )}
    </SelectWrapper>
  );
};

export default Container;

const availableOptions = [
  {
    value: "eth",
    label: (
      <OptionWrapper>
        <EthereumIcon size={18} />
        <OptionSpan>ETH (Ethereum)</OptionSpan>
      </OptionWrapper>
    ),
  },
  ...CONTRACT_ADDRESS.map(({ name, fullName, symbol }, index) => {
    return {
      key: index,
      value: name,
      label: (
        <OptionWrapper>
          <img src={symbol} style={{ width: 16, height: 16 }} />
          <OptionSpan>
            {name.toUpperCase()} ({fullName})
          </OptionSpan>
        </OptionWrapper>
      ),
    };
  }),
];

const ethOptions = [
  {
    value: "eth",
    label: (
      <OptionWrapper>
        <EthereumIcon size={18} />
        <OptionSpan>ETH (Ethereum)</OptionSpan>
      </OptionWrapper>
    ),
  },
];

const ercOptions = [
  ...CONTRACT_ADDRESS.map(({ name, fullName, symbol }, index) => {
    return {
      key: index,
      value: name,
      label: (
        <OptionWrapper>
          <img src={symbol} style={{ width: 16, height: 16 }} />
          <OptionSpan>
            {name.toUpperCase()} ({fullName})
          </OptionSpan>
        </OptionWrapper>
      ),
    };
  }),
];
