import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import { Helmet } from "react-helmet";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Label } from "reactstrap";

import LogoImage from "../../Resources/Images/logo_main.png";
import GlobalContext from "../../Hooks/globalContext";
import { HOST, NUMBER_REGEX } from "../../Utils/constants";
import { languagePack } from "../../Utils/language";
import useState from "../../Hooks/useState";
import useInput from "../../Hooks/useInput";
import Input from "../../Components/Common/Input";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-bottom: 50px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
`;

const CheckWrapper = styled(Label)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.darkGrey};
  font-size: 15px;
  font-weight: bold;
  margin: 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 30px;
`;

const AuthWrapper = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 10% 0px 10%;

  input {
    margin-bottom: 15px;
  }
  button {
    margin: 5px 0px;
  }
`;

const NotificationSpan = styled.span`
  font-size: 15px;
  font-weight: 500;
  padding: 7.5px 0px;
  text-align: center;
`;

const WalletBox = styled.pre`
  width: 100%;
  min-height: 50px;
  word-break: break-all;
  white-space: normal;
  text-align: center;
  background-color: #eaeaea;
  cursor: pointer;
  :hover {
    background-color: #bdbdbd;
  }
`;

const LinkWrapper = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 13px;
  text-align: center;
`;

const LinkInnerWrapper = styled.div`
  width: 100%;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  white-space: nowrap;
`;

const LinkSpan = styled(Link)`
  color: ${(props) => props.theme.darkGrey};
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  :hover {
    text-decoration: underline;
    color: grey;
  }
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;

const PaddingSpan = styled.span`
  padding-bottom: 10px;
  font-size: 13px;
`;
const LengthWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const AuthButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.mainColor};
  font-size: 14px;
  color: white;
  padding: 10px;
  border: 0;
  border-radius: 10px;
  :hover {
    opacity: 0.75;
  }
  :focus {
    outline: none;
  }
  cursor: pointer;
`;

const Clipboard = styled(CopyToClipboard)`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: ${(props) => props.theme.darkGrey};
  margin: 0;
  margin-bottom: 5px;
`;

const Delimiter = styled.span`
  margin: 0px 10px;
  color: #542d7f;
`;

const AuthLogo = styled.img`
  width: 100%;
  margin-bottom: 10px;
`;

/** Container  */

const Container = ({ history, page, match }) => {
  const { isLogin, language, isSession, getUser, modal } = useContext(GlobalContext);
  const walletState = useState();
  const emailInput = useInput({ defaultValue: "" });
  const passwordInput = useInput({ defaultValue: "" });
  const passwordCheckInput = useInput({ defaultValue: "" });
  const tokenInput = useInput({ defaultValue: "" });
  const recommendInput = useInput({ defaultValue: "" });

  const pagePermit = useState(false);
  const loginVerifyState = useState(false);
  const isChecked = useState(false);

  if (page === "wallet" && !walletState.state) {
    history.push("/login");
  }

  if (page === "login/verify" && !loginVerifyState.state) {
    history.push("/login");
  }

  if (page === "verify" && !pagePermit.state) {
    history.push("/login");
  }

  const checkboxOnClick = ({ target: { checked } }) => {
    isSession.setState(!checked);
    isChecked.setState(checked);
  };

  const inputClean = () => {
    emailInput.setValue("");
    passwordInput.setValue("");
    passwordCheckInput.setValue("");
    recommendInput.setValue("");
  };

  const AuthOnSubmit = (e) => {
    e.preventDefault();

    const config = {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };

    switch (page) {
      case "login":
        const loginParams = {
          email: emailInput.value,
          password: passwordInput.value,
          type: "web",
        };

        Axios.post(`${HOST}/login`, loginParams, config)
          .then(async (response) => {
            if (response.data.status) {
              await getUser(response.data.token);
              isLogin.setState(true);
              if (isChecked.state) {
                localStorage.setItem("token", response.data.token);
                isSession.setState(false);
              } else {
                sessionStorage.setItem("token", response.data.token);
                isSession.setState(true);
              }
              history.replace("/");
            } else {
              switch (response.data.code) {
                case "email":
                  Axios.post(`${HOST}/auth/send`, { email: emailInput.value }, config).then((response) => {
                    loginVerifyState.setState(true);
                    modal.setState(languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state));
                    history.push("/login/verify");
                  });
                  break;

                case "invalid":
                  modal.setState(languagePack("잘못된 ID 혹은 비밀번호입니다.", language.state));
                  break;

                case "server":
                  modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
                  break;

                default:
                  modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
                  break;
              }
            }
          })
          .catch((error) => {
            modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
          });
        break;

      case "register":
        if (passwordInput.value !== passwordCheckInput.value) {
          modal.setState(languagePack("두 비밀번호가 맞지 않습니다.", language.state));
          return;
        }

        const regsiterParams = {
          email: emailInput.value,
          password: passwordInput.value,
          recommend: recommendInput.value,
        };
        Axios.post(`${HOST}/register`, regsiterParams, config)
          .then((response) => {
            if (response.data.status) {
              loginVerifyState.setState(true);
              modal.setState(languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state));
              history.push("/login/verify");
            } else {
              switch (response.data.code) {
                case "duplicate":
                  modal.setState(languagePack("중복된 ID 입니다.", language.state));
                  break;
                case "server":
                  modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
                  break;
                case "invalid recommend":
                  modal.setState(languagePack("존재하지 않는 추천인입니다.", language.state));
                  break;
                default:
                  modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
          });
        break;

      case "forgot":
        const forgotParams = {
          email: emailInput.value,
        };
        Axios.post(`${HOST}/auth/send`, forgotParams, config)
          .then((response) => {
            if (response.data.status) {
              modal.setState(languagePack("인증 번호를 전송했습니다.", language.state));
              pagePermit.setState(true);
              history.push("/password/verify");
            } else {
              switch (response.data.code) {
                case "invalid":
                  modal.setState(languagePack("존재하지 않는 ID 입니다.", language.state));
                  break;
                default:
                  modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
          });
        break;

      case "verify":
        if (passwordInput.value !== passwordCheckInput.value) {
          modal.setState(languagePack("두 비밀번호가 맞지 않습니다.", language.state));
          return;
        }
        const verifyParams = {
          email: emailInput.value,
          password: passwordInput.value,
          token: tokenInput.value,
          type: "password",
        };

        Axios.post(`${HOST}/auth/verify`, verifyParams, config)
          .then((response) => {
            if (response.data.status) {
              modal.setState(languagePack("비밀번호 변경이 완료되었습니다.", language.state));
              inputClean();
              history.replace("/login");
            } else {
              switch (response.data.code) {
                case "invalid":
                  modal.setState(languagePack("존재하지 않는 ID 입니다.", language.state));
                  break;
                case "timeout":
                  modal.setState(languagePack("시간 초과입니다. 처음부터 다시 시도해주세요.", language.state));
                  history.replace("/password/forgot");
                  break;
                case "token":
                  modal.setState(languagePack("잘못된 코드입니다.", language.state));
                  break;
                default:
                  modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
          });
        break;
      case "login/verify":
        const loginVerifyParams = {
          email: emailInput.value,
          token: tokenInput.value,
          type: "register",
        };

        Axios.post(`${HOST}/auth/verify`, loginVerifyParams, config)
          .then((response) => {
            if (response.data.status) {
              walletState.setState({ address: response.data.address });
              inputClean();
              history.push("/register/wallet");
              modal.setState(languagePack("메일 인증이 완료되었습니다. 지갑정보를 확인하세요.", language.state));
            } else {
              switch (response.data.code) {
                case "invalid":
                  modal.setState(languagePack("존재하지 않는 ID 입니다.", language.state));
                  break;
                case "timeout":
                  modal.setState(languagePack("시간 초과입니다. 처음부터 다시 시도해주세요.", language.state));
                  history.replace("/login");
                  break;
                case "token":
                  modal.setState(languagePack("잘못된 코드입니다.", language.state));
                  break;
                default:
                  modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
                  break;
              }
              return;
            }
          })
          .catch((error) => {
            modal.setState(languagePack("서버 오류입니다. 잠시 후에 다시 시도해주세요.", language.state));
          });
        break;
      default:
        break;
    }
  };

  const LoginOnClick = () => {
    history.push("/login");
  };

  const RegisterOnClick = () => {
    history.push("/register");
  };

  const onCopy = ({ copy }) => {
    modal.setState(languagePack("지갑 주소가 복사되었습니다.", language.state));
  };

  const preload = () => {
    if (match.params.recommend) {
      recommendInput.setValue(match.params.recommend);
    }
  };

  useEffect(preload, []);

  return (
    <>
      <Wrapper>
        <AuthWrapper style={{ justifyContent: page === "login" && "center" }} onSubmit={AuthOnSubmit}>
          {page === "login" && (
            <LengthWrapper>
              <LogoWrapper>
                <AuthLogo src={LogoImage} />
              </LogoWrapper>
              <Input placeholder="E-Mail" type="email" {...emailInput} />
              <Input placeholder="Password" type="password" {...passwordInput} />
              <AuthButton>{languagePack("로그인", language.state)}</AuthButton>
              {/* <LinkWrapper style={{ paddingBottom: 10 }}>
                <LinkInnerWrapper>
                  <CheckWrapper>
                    <input
                      style={{ minHeight: 0, width: "auto", margin: 0, marginRight: 2.5 }}
                      type="checkbox"
                      onChange={checkboxOnClick}
                      checked={isChecked.state}
                      required={false}
                    />
                    {languagePack("자동 로그인", language.state)}
                  </CheckWrapper>
                  <Delimiter>|</Delimiter>
                  <div>
                    <LinkSpan to="/register">{languagePack("회원가입", language.state)}</LinkSpan>
                    <Delimiter>|</Delimiter>
                    <LinkSpan to="/password/forgot">{languagePack("비밀번호 찾기", language.state)}</LinkSpan>
                  </div>
                </LinkInnerWrapper>
              </LinkWrapper> */}
            </LengthWrapper>
          )}
          {page === "register" && (
            <LengthWrapper style={{ paddingTop: 20 }}>
              {/* <TitleSpan>Register</TitleSpan> */}
              <Input placeholder="* E-Mail" type="email" {...emailInput} />
              <Input placeholder="* Password" type="password" {...passwordInput} />
              <Input placeholder="* Password Check" type="password" {...passwordCheckInput} />
              <Input required={false} placeholder="Recommend" type="text" {...recommendInput} />
              <AuthButton>{languagePack("확인", language.state)}</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/">{languagePack("돌아가기", language.state)}</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "forgot" && (
            <LengthWrapper style={{ paddingTop: 20 }}>
              <Input placeholder="E-Mail" type="text" {...emailInput} />
              <AuthButton>{languagePack("인증번호 전송", language.state)}</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/login">{languagePack("돌아가기", language.state)}</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "verify" && (
            <LengthWrapper style={{ paddingTop: 20 }}>
              <Input placeholder="New Password" type="password" {...passwordInput} />
              <Input placeholder="Password Check" type="password" {...passwordCheckInput} />
              <InputLabel style={{ padding: "10px 0px" }}>
                <PaddingSpan>{languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state)}</PaddingSpan>
                <Input placeholder="Token" type="text" {...tokenInput} />
              </InputLabel>
              <AuthButton>{languagePack("인증", language.state)}</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/login">{languagePack("돌아가기", language.state)}</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "login/verify" && (
            <LengthWrapper style={{ paddingTop: 20 }}>
              <InputLabel>
                <PaddingSpan>{languagePack("5분 안에 이메일에 전송된 코드를 입력하세요.", language.state)}</PaddingSpan>
                <Input placeholder="Token" type="text" {...tokenInput} />
              </InputLabel>
              <AuthButton>{languagePack("인증", language.state)}</AuthButton>
              <LinkWrapper>
                <LinkSpan to="/login">{languagePack("돌아가기", language.state)}</LinkSpan>
              </LinkWrapper>
            </LengthWrapper>
          )}
          {page === "wallet" && walletState.state && (
            <LengthWrapper style={{ paddingTop: 20 }}>
              <NotificationSpan>
                {languagePack("당신의 지갑주소입니다. QR코드를 스캔하세요.", language.state)}
              </NotificationSpan>
              <QRCode value={walletState.state.address} size={180} style={{ marginBottom: 25 }} />
              <Clipboard text={walletState.state.address} onCopy={onCopy.bind(null, { copy: "wallet" })}>
                <WalletBox>{walletState.state.address}</WalletBox>
              </Clipboard>
              <AuthButton type="button" onClick={LoginOnClick}>
                {languagePack("돌아가기", language.state)}
              </AuthButton>
            </LengthWrapper>
          )}
        </AuthWrapper>
      </Wrapper>
    </>
  );
};

export default Container;
