import React from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

const SmallButton = styled.button`
  background-color: ${(props) => props.theme.mainColor};
  padding: 7.5px 15px;
  border: 0;
  border-radius: 2.5px;
  font-weight: bold;
  color: white;
  :focus {
    outline: none;
  }
  :hover {
    opacity: 0.75;
  }
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = ({ text, style, type, onClick = () => null, disabled }) => (
  <SmallButton style={style} onClick={disabled ? () => null : onClick} type="button">
    {disabled ? <ClipLoader sizeUnit={"px"} size={15} color={"white"} /> : text}
  </SmallButton>
);

export default Button;
