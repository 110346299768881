import React from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

const Button = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: ${(props) => props.theme.mainColor};
  width: 100%;
  color: white;
  padding: 10px;
  :hover {
    opacity: 0.5;
  }
  :focus {
    outline: none;
  }
`;

const GreyButton = styled.button`
  border: 0;
  border-radius: 5px;
  background-color: ${(props) => props.theme.darkGrey};
  width: 100%;
  color: white;
  padding: 10px;
  :focus {
    outline: none;
  }
`;

const DefaultButton = ({
  style = {},
  onClick = () => null,
  text = "null",
  type = "button",
  disabled = false,
  grey = false,
}) => {
  return grey ? (
    <GreyButton style={style}>{text}</GreyButton>
  ) : (
    <Button style={style} onClick={disabled ? () => null : onClick} type={type}>
      {disabled ? <ClipLoader sizeUnit={"px"} size={15} color={"white"} /> : text}
    </Button>
  );
};

export default DefaultButton;
