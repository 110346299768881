import React, { useEffect } from "react";
import styled from "styled-components";
import Routes from "./Components/Routes";
import Helmet from "react-helmet";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import axios from "axios";
import Web3 from "web3";
import { Button, Input as _Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from "reactstrap";
import { Transaction } from "ethereumjs-tx";

// import ReactGA from "react-ga";
import {
  HOST,
  NODE_ENDPOINT,
  GAS_PRICE_API,
  CHAIN_ID,
  CHAIN_NAME,
  CONTRACT_ADDRESS,
  ETHERSCAN_TX_URL,
} from "./Utils/constants";
import Theme from "./Styles/Theme";
import GlobalContext from "./Hooks/globalContext";
import useState from "./Hooks/useState";
import GlobalStyles from "./Styles/GlobalStyle";
import { languagePack } from "./Utils/language";
import { ToFixed } from "./Utils/util";

import ABI from "./Resources/Contract/gip";

const Wrapper = styled.div`
  height: 100%;
  background-color: #fafafa;
`;

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const web3 = new Web3(
  new Web3.providers.HttpProvider(NODE_ENDPOINT, {
    headers: [],
  })
);

// ReactGA.initialize("UA-161528891-1");

const App = () => {
  const getLanguage = (myLanguage) => {
    switch (myLanguage) {
      case "ko":
        return "korean";
      case "en":
        return "korean";
      default:
        return "korean";
    }
  };

  const isLogin = useState(
    (localStorage.getItem("token") ? true : false) || (sessionStorage.getItem("token") ? true : false)
  );
  const toggleMain = useState(false);
  const contract = useState(null);
  const modal = useState(false);
  const balance = useState({});
  const isSession = useState(sessionStorage.getItem("token") ? true : localStorage.getItem("token") ? false : null);
  const user = useState(null);
  const language = useState(getLanguage(navigator.language.substring(0, 2)));
  const sidebar = useState(false);
  const pushToken = useState(null);

  const device = useState(null);

  const logout = () => {
    isLogin.setState(false);
    user.setState({});
    toggleMain.setState(!toggleMain.state);
    balance.setState({});
    if (isSession.state) {
      sessionStorage.removeItem("token");
    } else {
      localStorage.removeItem("token");
    }
  };

  const setLanguage = ({ target }) => {
    if (target.value !== "") {
      language.setState(target.value);
    }
  };

  const httpHeader = (token = null) => {
    const httpHeader = {
      headers: {
        Authorization: `Bearer ${
          token
            ? token
            : sessionStorage.getItem("token")
            ? sessionStorage.getItem("token")
            : localStorage.getItem("token")
        }`,
      },
    };
    return httpHeader;
  };

  const getUser = async (token = null) => {
    try {
      const me = await axios.post(`${HOST}/user/info`, { detail: true }, httpHeader(token));
      user.setState(me.data.user);
      return me.data.user;
    } catch (e) {
      if (e.message === "Request failed with status code 401") {
        isLogin.setState(false);
        user.setState({});
        if (isSession.state) {
          sessionStorage.removeItem("token");
        } else {
          localStorage.removeItem("token");
        }
        return "expired";
      }
    }
  };

  const sendEth = async ({ address, fee, to, amount, isLoading, confirm, reject, privateKey, txCount }) => {
    const count = txCount.state ? txCount.state : await getTxCount(address);
    const gasPrice = (fee / 10).toString();
    const weiGasPrice = web3.utils.toWei(gasPrice, "gwei");
    const convertedGas = web3.utils.fromWei(`210000`, "gwei");
    const hexGasPrice = web3.utils.toHex(weiGasPrice);

    const requestBody = {
      rawTransaction: {
        from: address,
        gasPrice: hexGasPrice,
        gasLimit: web3.utils.toHex(210000),
        to,
        value: web3.utils.toHex(web3.utils.toWei(amount.toString(), "ether")),
        chainId: CHAIN_ID,
        nonce: web3.utils.toHex(count),
      },
      CHAIN_NAME,
      amount,
      fee: convertedGas * gasPrice,
      to,
      sendType: "eth",
    };
    const transaction = new Transaction(requestBody.rawTransaction, { chain: requestBody.CHAIN_NAME });
    transaction.sign(privateKey);
    const tx = "0x" + transaction.serialize().toString("hex");
    web3.eth.sendSignedTransaction(tx, async (error, hash) => {
      if (error) {
        reject(error);
      } else {
        txCount.setState(count + 1);
        window.open(`${ETHERSCAN_TX_URL}/${hash}`);
        confirm(hash);
      }
    });
    isLoading.setState(false);
  };

  const sendToken = async ({ address, fee, to, amount, isLoading, confirm, reject, symbol, privateKey, txCount }) => {
    const count = txCount.state ? txCount.state : await getTxCount(address);
    const gasPrice = (fee / 10).toString();
    const weiGasPrice = web3.utils.toWei(gasPrice, "gwei");
    const convertedGas = web3.utils.fromWei(`210000`, "gwei");
    const hexGasPrice = web3.utils.toHex(weiGasPrice);

    const { data: con } = await axios.post(`${HOST}/api/tokens`, null, httpHeader());

    const [sorted] = con.filter(({ contract_address: address, ticker: name }, index) => {
      if (name.toUpperCase() === symbol.toUpperCase()) {
        return address;
      }
    });

    let dynamicAmount = web3.utils.toWei(amount);
    if (symbol.toLowerCase() === "usdt") {
      dynamicAmount = dynamicAmount.substring(0, dynamicAmount.length - 12);
    }

    const requestBody = {
      rawTransaction: {
        from: address,
        gasPrice: hexGasPrice,
        gasLimit: web3.utils.toHex(210000),
        to: sorted.contract_address,
        value: "0x0",
        data: new web3.eth.Contract(ABI, sorted.contract_address).methods.transfer(to, dynamicAmount).encodeABI(),
        nonce: web3.utils.toHex(count),
      },
      CHAIN_NAME,
      amount,
      fee: convertedGas * gasPrice,
      to,
      sendType: symbol,
    };

    const transaction = new Transaction(requestBody.rawTransaction, { chain: requestBody.CHAIN_NAME });
    transaction.sign(privateKey);
    const tx = "0x" + transaction.serialize().toString("hex");
    web3.eth.sendSignedTransaction(tx, async (error, hash) => {
      if (error) {
        reject(error);
      } else {
        txCount.setState(count + 1);
        window.open(`${ETHERSCAN_TX_URL}/${hash}`);
        confirm(hash);
      }
    });

    isLoading.setState(false);
  };

  const getTxCount = async (address) => {
    const count = await web3.eth.getTransactionCount(address, "pending");
    return count;
  };

  const refreshEth = async (symbol, rowAddress) => {
    balance.setState({ ...balance.state, eth: undefined });
    const _balance = await web3.eth.getBalance(rowAddress ? rowAddress : user.state.wallet_address);
    const convertedBalance = ToFixed(web3.utils.fromWei(_balance), 10000);
    return convertedBalance;
  };

  const refreshToken = async (symbol, rowAddress) => {
    let _balance = await contract.state[symbol].methods
      .balanceOf(rowAddress ? rowAddress : user.state.wallet_address)
      .call();

    if (symbol === "usdt") {
      _balance = _balance + "000000000000";
    }

    const convertedBalance = ToFixed(web3.utils.fromWei(_balance), 10000);
    return convertedBalance;
  };
  const getBalance = async (symbol, admin = false, rowAddress = null) => {
    if (symbol === "eth") {
      const _eth = await refreshEth(symbol, rowAddress);
      balance.setState({ ...balance.state, eth: _eth });
      return;
    } else {
      if (!symbol) {
        const eth = await refreshEth(symbol, rowAddress);

        const json = {
          eth,
        };
        const erc20 = await Promise.all(
          CONTRACT_ADDRESS.map(async ({ name }) => {
            json[name] = await refreshToken(name, rowAddress);
          })
        );

        if (!admin) {
          balance.setState(json);
        } else {
          return json;
        }
        return;
      } else {
        const json = { ...balance.state };
        json[symbol] = undefined;
        balance.setState(json);

        const tokenBalance = await refreshToken(symbol, rowAddress);

        const result = { ...balance.state };
        result[symbol] = tokenBalance;

        balance.setState(result);

        return;
      }
    }
  };

  const balanceConvert = (eth) => {
    try {
      const numberEth = Number(eth);
      const converted = numberEth.toFixed(9);
      const result = converted.substring(0, converted.length - 1);
      return Number(result);
    } catch (e) {
      return eth;
    }
  };

  const sidebarToggle = () => {
    sidebar.setState(!sidebar.state);
  };

  const preload = () => {
    const pcDevice = "win16|win32|win64|mac|macintel";
    if (navigator.platform) {
      if (pcDevice.indexOf(navigator.platform.toLowerCase()) < 0) {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.search("android") > -1) {
          device.setState("android");
        } else if (userAgent.search("iphone") > -1 || userAgent.search("ipod") > -1 || userAgent.search("ipad") > -1) {
          device.setState("ios");
        } else {
          device.setState("mobile");
        }
      } else {
        device.setState("pc");
      }
    }

    window.addEventListener("message", function (event) {
      if (event.data && event.data !== "undefined") {
        try {
          const parsed = JSON.parse(event.data);
          pushToken.setState(parsed.pushToken);
        } catch (e) {}
      }
    });
    document.addEventListener("message", function (event) {
      if (event.data && event.data !== "undefined") {
        try {
          const parsed = JSON.parse(event.data);
          pushToken.setState(parsed.pushToken);
        } catch (e) {}
      }
    });
  };

  const setContract = () => {
    const userInit = async () => {
      await getUser();
    };

    const contractInit = async () => {
      await loginHandler();
    };

    if (!user.state && isLogin.state) {
      userInit();
    }

    if (!contract.state && isLogin.state) {
      contractInit();
    }
  };

  const loginHandler = () => {
    const initialize = async () => {
      const _user = await getUser();
      if (_user === "expired") {
        modal.setState(languagePack("보안을 위해 로그아웃합니다. 다시 로그인해주세요.", language.state));
        logout();
        return;
      }
      const contracts = [];
      // const { data } = await axios.post(`${HOST}/api/tokens`);

      await Promise.all(
        CONTRACT_ADDRESS.map(async ({ address, name, abi }, index) => {
          contracts[name] = new web3.eth.Contract(abi.default, address);
        })
      );
      contract.setState(contracts);
    };
    if (isLogin.state) {
      initialize();
    }
  };
  const toggleModal = ({ text }) => {
    modal.setState(text ? text : null);
  };

  useEffect(() => {
    setContract();
  }, [isLogin.state]);

  useEffect(() => {
    preload();
  }, []);

  const contextValue = {
    isLogin,
    toggleModal,
    modal,
    user,
    getUser,
    logout,
    language,
    setLanguage,
    httpHeader,
    web3,
    getTxCount,
    balanceConvert,
    getBalance,
    contract,
    sendToken,
    sendEth,
    isSession,
    sidebarToggle,
    pushToken,
    modal,
    balance,
    toggleMain,
  };

  return (
    <>
      <GlobalStyles />
      <GlobalContext.Provider value={contextValue}>
        <ThemeProvider theme={Theme}>
          <Router>
            <Helmet>
              <meta name="viewport" content="initial-scale=1.0,user-scalable=no,maximum-scale=1,width=device-width" />
            </Helmet>
            <div>
              <Modal centered={true} isOpen={!!modal.state} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}> {languagePack("알림", language.state)}</ModalHeader>
                <ModalBody>{modal.state}</ModalBody>
                <ModalFooter>
                  <Button style={{ backgroundColor: Theme.mainColor, border: 0 }} onClick={toggleModal}>
                    {languagePack("확인", language.state)}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <Wrapper>
              <InnerWrapper>
                <Routes />
              </InnerWrapper>
            </Wrapper>
          </Router>
        </ThemeProvider>
      </GlobalContext.Provider>
    </>
  );
};

export default App;
