import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Axios from "axios";

import {
  LogoutIcon,
  CodeIcon,
  UserCircleIcon,
  RightArrowIcon,
  CoinIcon,
  LanguageIcon,
  ExchangeIcon,
  BankIcon,
  NoticeIcon,
} from "../../Components/Icons";
import { languagePack } from "../../Utils/language";
import Theme from "../../Styles/Theme";
import globalContext from "../../Hooks/globalContext";
import PageWrapper from "../../Components/Common/PageWrapper";
import { CLIENT_HOST, HOST } from "../../Utils/constants";
import useState from "../../Hooks/useState";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 0px 20px;
  :not(:last-child) {
    margin-bottom: 5px;
  }
  :not(:first-child) {
    cursor: pointer;
    :hover {
      background-color: ${(props) => props.theme.lightGrey};
      opacity: 0.75;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
`;

const ItemLabel = styled.span`
  color: black;
  margin-left: 10px;
`;

const MenuItem = ({ language, title, icon, onClick, code }) => {
  return (
    <Row onClick={onClick}>
      <Item>
        {icon}
        <ItemLabel>{title}</ItemLabel>
      </Item>
      <Item>
        {onClick && !code && <RightArrowIcon color={Theme.darkGrey} size={18} />}
        {code && <ItemLabel>{code}</ItemLabel>}
      </Item>
    </Row>
  );
};

const Page = ({ history }) => {
  const { httpHeader, language, user, logout, modal } = useContext(globalContext);
  const point = useState("");
  const onCopy = () => {
    modal.setState(languagePack("추천인 코드가 복사되었습니다.", language.state));
  };
  const getPoint = async () => {
    const { data } = await Axios.post(`${HOST}/user/point`, null, httpHeader());
    point.setState(data);
  };

  useEffect(() => {
    getPoint();
  }, []);

  return (
    <PageWrapper contentStyle={{ alignItems: "flex-start" }}>
      <Wrapper>
        {user.state && (
          <>
            <MenuItem
              icon={<UserCircleIcon color={"grey"} size={24} />}
              title={`${user.state.email}`}
              language={language}
            />
            <CopyToClipboard text={`${CLIENT_HOST}/register/${user.state.referral_code}`} onCopy={onCopy}>
              <MenuItem
                icon={<CodeIcon color={"grey"} size={24} />}
                title={languagePack("추천 코드", language.state)}
                code={user.state.referral_code}
                language={language}
              />
            </CopyToClipboard>
            <MenuItem
              icon={<CoinIcon color={"grey"} />}
              title={"GIPK"}
              code={`${point.state} GIPK`}
              language={language}
            />
            {/* <MenuItem
              icon={<NoticeIcon color={"grey"} />}
              title={languagePack("공지사항", language.state)}
              onClick={() => history.push("/notice")}
              language={language}
            /> */}
            <MenuItem
              icon={<ExchangeIcon color={"grey"} />}
              title={languagePack("GIPK 전환", language.state)}
              language={language}
              onClick={() => history.push("/exchange")}
            />
            {/* <MenuItem
              icon={<BankIcon color={"grey"} />}
              title={languagePack("토큰 스왑", language.state)}
              language={language}
              onClick={() => history.push("/swap")}
            /> */}
            {/* <MenuItem
              icon={<LanguageIcon color={"grey"} />}
              title={languagePack("언어", language.state)}
              language={language}
              onClick={() => history.push("/language")}
            /> */}
            <MenuItem
              icon={<LogoutIcon color={"grey"} />}
              title={languagePack("로그아웃", language.state)}
              language={language}
              onClick={logout}
            />
          </>
        )}
      </Wrapper>
    </PageWrapper>
  );
};
export default Page;
