import React, { useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import Web3 from "web3";
import Axios from "axios";

import { CONTRACT_ADDRESS, HOST, NODE_ENDPOINT } from "../../Utils/constants";
import useState from "../../Hooks/useState";
import globalContext from "../../Hooks/globalContext";
import TokenRow from "../../Components/Main/TokenRow";
import MyWalletInfo from "../../Components/Main/MyWalletInfo";
import PageWrapper from "../../Components/Common/PageWrapper";

const web3 = new Web3(
  new Web3.providers.HttpProvider(NODE_ENDPOINT, {
    headers: [],
  })
);

const Content = styled.div`
  width: 100%;
  padding: 15px;
`;

const Page = ({ history, page }) => {
  const { getBalance, contract, user, balance, toggleMain, httpHeader } = useContext(globalContext);
  const marketcap = useState(null);
  const point = useState(null);

  const getMarketcap = async () => {
    const { data } = await Axios.post(`${HOST}/api/marketcap`, null, httpHeader());
    marketcap.setState({ ...data, price_gipk: data.price_gip });
  };

  const getPoint = async () => {
    const { data } = await Axios.post(`${HOST}/user/point`, null, httpHeader());
    point.setState(data);
  };

  const preload = () => {
    if (user.state && contract.state && !toggleMain.state) {
      toggleMain.setState(true);
      getBalance();
      getPoint();
    }
  };

  const marketcapPreload = () => {
    getMarketcap();
  };

  useEffect(() => {
    marketcapPreload();
    getPoint();
  }, []);

  useEffect(preload, [user.state, contract.state]);
  return (
    <PageWrapper>
      {user.state && <MyWalletInfo />}
      <Content>
        <TokenRow marketcap={marketcap} balance={balance.state.eth} symbol={"eth"} fullName={"Ethereum"} />
        {CONTRACT_ADDRESS.map(({ name, fullName, symbol }, index) => {
          return (
            <TokenRow
              key={`key.${index}`}
              marketcap={marketcap}
              balance={balance.state[name]}
              symbol={name}
              fullName={fullName}
              logo={symbol}
            />
          );
        })}
        <TokenRow
          pointMain={getPoint}
          marketcap={marketcap}
          balance={point.state}
          symbol={"gipk"}
          fullName={"GIPK"}
          logo={CONTRACT_ADDRESS[0].symbol}
          point={true}
        />
      </Content>
    </PageWrapper>
  );
};

export default Page;
