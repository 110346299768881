import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import Web3 from "web3";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import AdminSideBar from "../../Components/Admin/AdminSideBar";
import GlobalContext from "../../Hooks/globalContext";
import { NODE_ENDPOINT } from "../../Utils/constants";
import "../../Styles/pagination.css";
import useState from "../../Hooks/useState";
import { languagePack } from "../../Utils/language";
import AdminHeader from "../../Components/Admin/AdminHeader";
import UserPage from "../../Components/Admin/Pages/UserPage";
import LogPage from "../../Components/Admin/Pages/LogPage";
import BalancePage from "../../Components/Admin/Pages/BalancePage";
import ConfigPage from "../../Components/Admin/Pages/ConfigPage";
import ExchangePage from "../../Components/Admin/Pages/ExchangePage";
import NoticePage from "../../Components/Admin/Pages/NoticePage";
import WritePage from "../../Components/Admin/Pages/WritePage";

const Admin = styled.div`
  display: flex;
  height: 100%;
`;

const Main = styled.div`
  width: calc(100% - 250px);
  background-color: ${(props) => props.theme.lightGrey};
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 90px);
  padding: 0px 20px 20px 20px;
`;

const Content = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
`;

const Container = ({ history, location }) => {
  const { isLogin, user, getUser, logout, language } = useContext(GlobalContext);
  const page = useState("userList");
  const focus = useState(null);
  const preload = async () => {
    const _user = await getUser();
    if (_user === "expired") {
      return;
    }
    if (_user.is_admin === 0) {
      history.push("/");
    }
  };
  useEffect(() => {
    preload();
  }, []);

  return (
    <Admin>
      {user.state && (
        <>
          <Helmet>
            <title>Admin | Wallet</title>
          </Helmet>
          <AdminSideBar page={page} />
          <Main>
            <AdminHeader page={page.state} />
            <ContentWrapper>
              <Content>
                {page.state === "shopList" && (
                  <LogPage
                    adminPage={page}
                    tableName="shop"
                    columnList="shopList"
                    columnName="shopList"
                    searchList={[
                      ["id", "번호"],
                      ["email", "관리자 이메일"],
                    ]}
                  />
                )}
                {page.state === "noticeList" && (
                  <NoticePage adminPage={page} focus={focus} hostPath={"/admin/notice/list"} />
                )}
                {page.state === "noticeWrite" && <WritePage adminPage={page} hostPath={"/admin/notice/crud"} />}
                {page.state === "noticeUpdate" && (
                  <WritePage adminPage={page} type="update" focus={focus} hostPath={"/admin/notice/crud"} />
                )}
                {page.state === "lockupList" && (
                  <LogPage
                    adminPage={page}
                    tableName="lock_up"
                    columnName="lockupList"
                    columnList="lockList"
                    searchList={[
                      ["t.id", "번호"],
                      ["t.user_id", "유저 번호"],
                      ["t.user_email", "유저 이메일"],
                      ["t.token_type", "토큰 타입"],
                    ]}
                  />
                )}
                {page.state === "userList" && <UserPage adminPage={page} hostPath={"/admin/users"} />}
                {page.state === "stakingList" && (
                  <LogPage
                    adminPage={page}
                    tableName="staking"
                    columnName="stakingList"
                    columnList="stakingList"
                    searchList={[
                      ["t.id", "번호"],
                      ["t.user_id", "유저 번호"],
                    ]}
                  />
                )}
                {page.state === "swapList" && (
                  <LogPage
                    adminPage={page}
                    tableName="app_swap_log"
                    columnName="swapList"
                    columnList="swapList"
                    searchList={[["t.id", "번호"]]}
                  />
                )}
                {page.state === "balance" && <BalancePage adminPage={page} hostPath={"/admin/log/point"} />}
                {page.state === "sendLog" && (
                  <LogPage
                    adminPage={page}
                    tableName="app_send_log"
                    columnName="sendLog"
                    columnList="app_send_log"
                    searchList={[
                      ["id", "번호"],
                      ["t.send_email", "보내는 유저 메일"],
                      ["t.receive_email", "받는 유저 메일"],
                      ["t.send_address", "보내는 지갑주소"],
                      ["t.receive_address", "받는 지갑주소"],
                      ["t.type", "전송 토큰 심볼"],
                    ]}
                  />
                )}
                {page.state === "shopExchange" && (
                  <LogPage
                    adminPage={page}
                    tableName="app_send_log"
                    columnName="shopExchange"
                    columnList="app_send_log"
                    condition=" shop_id is not null "
                    searchList={[
                      ["id", "번호"],
                      ["t.send_email", "보내는 유저 메일"],
                      ["t.receive_email", "받는 유저 메일"],
                      ["t.send_address", "보내는 지갑주소"],
                      ["t.receive_address", "받는 지갑주소"],
                      ["t.type", "전송 토큰 심볼"],
                    ]}
                  />
                )}
                {page.state === "withdraw" && (
                  <ExchangePage
                    adminPage={page}
                    tableName="app_withdraw"
                    columnName="withdraw"
                    // condition="is_delete = 'N'"
                    columnList="withdraw"
                    searchList={[
                      ["t.id", "번호"],
                      ["t.user_id", "유저 번호"],
                    ]}
                  />
                )}
                {/* {page.state === "stakingList" && (
                  <LogPage
                    hostPath="/admin/log/staking"
                    adminPage={page}
                    tableName="staking"
                    columnName="stakingList"
                    columnList="staking"
                    searchList={[
                      ["s.id", "번호"],
                      ["u.email", "유저 이메일"],
                    ]}
                  />
                )} */}
                {/* {page.state === "swap" && (
                  <LogPage
                    hostPath={"/admin/log/swap"}
                    adminPage={page}
                    tableName="swap"
                    columnName="swapList"
                    searchList={[
                      ["s.id", "번호"],
                      ["s.token_type_receive", "지급할 토큰 타입"],
                      ["s.token_type_send", "받은 토큰 타입"],
                      ["s.hash_from", "관리자가 받은 tx 해시"],
                      ["s.hash_to", "관리자가 보낸 tx 해시"],
                      ["s.status", "상태 (0=미지급,1=지급) 숫자로입력"],
                    ]}
                  />
                )} */}
                {page.state === "config" && <ConfigPage adminPage={page} />}
              </Content>
            </ContentWrapper>
          </Main>
        </>
      )}
    </Admin>
  );
};

export default Container;
