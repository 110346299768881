import React, { useEffect, useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  Container,
  makeStyles,
  Card,
  Grid,
  CardHeader,
  CardContent,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@material-ui/core";

import xlsx from "xlsx";
import DropZone from "Components/Common/DropZone";
import DefaultButton from "Components/Common/DefaultButton";
import SmallButton from "Components/Common/SmallButton";
import GlobalContext from "Hooks/globalContext";
import { HOST, NUMBER_REGEX, AMOUNT_REGEX } from "Utils/constants";
import Input from "Components/Common/Input";
import useInput from "Hooks/useInput";
import Loader from "Components/Common/Loader";
import { numberWithCommas } from "Utils/util";
import Axios from "axios";
import DropZoneExcel from "Components/Common/DropZoneExcel";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
  },
}));
const Page = ({ adminPage, focus }) => {
  const classes = useStyles();
  const { httpHeader, web3, modal } = useContext(GlobalContext);
  const [config, setConfig] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [single, setSingle] = useState(null);
  const [backdrop, setBackdrop] = useState(null);
  const [currencyType, setCurrencyType] = useState("dollar");

  const [excel, setExcel] = useState(null);

  const coinName = useInput({ defaultValue: "" });
  const coinTicker = useInput({ defaultValue: "" });
  const coinContract = useInput({ defaultValue: "", maxLength: 43 });
  const defaultMarketcap = useInput({ defaultValue: "" });

  const stakingDay = useInput({ defaultValue: "" });
  const stakingPer = useInput({ defaultValue: "" });
  const stakingMin = useInput({ defaultValue: "" });
  const stakingAvailable = useInput({ defaultValue: "" });
  const payFee = useInput({ defaultValue: "" });

  const deleteCheck = useInput({ defaultValue: "" });

  const defaultMarketcapUpdate = useInput({ defaultValue: "" });

  const [singleUpdate, setSingleUpdate] = useState(null);
  const [token, setToken] = useState(null);

  const preload = () => {
    getConfig();
  };

  const onSubmitUpdate = async (e) => {
    e.preventDefault();

    if (isNaN(Number(defaultMarketcapUpdate.value))) {
      return modal.setState("올바른 입력이 아닙니다.");
    }

    const frm = new FormData();
    frm.append("name", dialog.name);
    frm.append("ticker", dialog.ticker);
    frm.append("currencyType", currencyType);
    frm.append("defaultMarketcap", defaultMarketcapUpdate.value);
    frm.append("image", singleUpdate);

    const _header = httpHeader();
    _header.headers["Content-Type"] = "multipart/form-data";
    setBackdrop(true);
    const { data } = await Axios.post(`${HOST}/admin/coin/update`, frm, _header);
    getConfig();
    coinName.setValue("");
    coinTicker.setValue("");
    coinContract.setValue("");
    defaultMarketcapUpdate.setValue("");
    setSingleUpdate(null);
    setBackdrop(false);
  };

  const onSubmitAddCoin = async (e) => {
    e.preventDefault();
    if (!single) {
      return modal.setState("심볼 이미지 파일을 등록해주세요.");
    }
    if (coinName.value === "") {
      return modal.setState("코인 이름을 입력해주세요.");
    }
    if (coinTicker.value === "") {
      return modal.setState("코인 티커를 입력해주세요");
    }
    if (coinContract.value.length !== 42 || coinContract.value === "") {
      return modal.setState("올바른 컨트랙트 주소를 입력해주세요.");
    }

    if (defaultMarketcap.value === "") {
      return modal.setState("기본 설정 마켓캡이 등록되지 않았습니다.");
    }

    if (isNaN(Number(defaultMarketcap.value))) {
      return modal.setState("올바른 입력이 아닙니다.");
    }

    const frm = new FormData();
    frm.append("currencyType", currencyType);
    frm.append("name", coinName.value);
    frm.append("ticker", coinTicker.value);
    frm.append("contract", coinContract.value);
    frm.append("defaultMarketcap", defaultMarketcap.value);
    frm.append("image", single);

    const _header = httpHeader();
    _header.headers["Content-Type"] = "multipart/form-data";
    setBackdrop(true);
    const { data } = await Axios.post(`${HOST}/admin/coin/add`, frm, _header);
    getConfig();
    coinName.setValue("");
    coinTicker.setValue("");
    coinContract.setValue("");
    defaultMarketcap.setValue("");
    setSingle(null);
    setBackdrop(false);
  };

  const getConfig = async () => {
    setBackdrop(true);
    const { data } = await Axios.post(`${HOST}/admin/setting/app`, {}, httpHeader());
    stakingDay.setValue(data.config.staking_day);
    stakingPer.setValue(data.config.staking_per);
    stakingMin.setValue(data.config.staking_min);
    stakingAvailable.setValue(data.config.staking_available);
    payFee.setValue(data.config.pay_fee);

    setToken(data.token);
    setBackdrop(false);
  };

  const onSubmitConfig = async (e) => {
    e.preventDefault();
    if (
      isNaN(Number(stakingDay.value)) ||
      isNaN(Number(stakingPer.value)) ||
      isNaN(Number(stakingMin.value)) ||
      isNaN(Number(payFee.value)) ||
      isNaN(Number(stakingAvailable.value))
    ) {
      return modal.setState("잘못된 입력입니다.");
    }
    if (Number(stakingPer.value) > 100 || Number(stakingPer.value) < 0) {
      return modal.setState("잘못된 입력입니다.");
    }
    if (Number(payFee.value) > 100 || Number(payFee.value) < 0) {
      return modal.setState("잘못된 입력입니다.");
    }
    if (Number(stakingDay.value) < 0) {
      return modal.setState("잘못된 입력입니다.");
    }
    if (Number(stakingPer.value) < 0) {
      return modal.setState("잘못된 입력입니다.");
    }
    if (Number(stakingMin.value) < 0) {
      return modal.setState("잘못된 입력입니다.");
    }
    if (Number(stakingAvailable.value) < 0) {
      return modal.setState("잘못된 입력입니다.");
    }
    if (Number(stakingAvailable.value) > 1) {
      return modal.setState("잘못된 입력입니다.");
    }

    const { data } = await Axios.post(
      `${HOST}/admin/setting/app/save`,
      {
        stakingDay: stakingDay.value,
        stakingPer: stakingPer.value,
        stakingMin: stakingMin.value,
        payFee: payFee.value,
        stakingAvailable: stakingAvailable.value,
      },
      httpHeader()
    );
    getConfig();
    modal.setState("저장되었습니다.");
  };

  const blobToXlsx = () => {
    const workbook = xlsx.read(excel.binary, { type: "binary" }).Sheets;
    const content = workbook["Sample"];
    const result = [];
    for (let key in content) {
      const tag = key.substring(0, 1);

      const num = Number(key.substring(1, key.length));
      if (num > 1) {
        switch (tag) {
          case "A":
            result[num] = {
              ...result[num],
              email: content[key].v,
            };
            break;
          case "B":
            result[num] = {
              ...result[num],
              name: content[key].v,
            };
            break;
          case "C":
            result[num] = {
              ...result[num],
              date: content[key].v,
            };
            break;
          case "D":
            result[num] = {
              ...result[num],
              phone: content[key].v,
            };
            break;
          case "E":
            result[num] = {
              ...result[num],
              pin: content[key].v,
            };
            break;
        }
      }
    }
    return result.filter(function (el) {
      return el != null;
    });
  };

  const onSubmitExcel = async (e) => {
    e.preventDefault();
    const result = blobToXlsx();
    const { data } = await Axios.post(`${HOST}/admin/excel/register`, result, httpHeader());

    if (data.status) {
      setExcel(null);
      modal.setState("가입링크가 전송되었습니다.");
    }
  };

  useEffect(preload, []);
  return token ? (
    <Container maxWidth="lg">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialog}
        onClose={() => {
          setDialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{"코인 변경"}</DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmitUpdate}>
            <TextField
              size="small"
              fullWidth
              label={"기본 설정 마켓캡 ($)"}
              margin="dense"
              variant="outlined"
              required={true}
              {...defaultMarketcapUpdate}
            />
            <FormControl style={{ marginTop: 10 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">가격 표기 방법</FormLabel>
              <Typography variant="caption">(원화 선택시 달러 가격에서 원화로 환산된 금액으로 표기됨)</Typography>
              <RadioGroup
                onChange={({ target: { value } }) => {
                  setCurrencyType(value);
                }}
                value={currencyType}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="dollar" control={<Radio />} label="달러" />
                <FormControlLabel value="won" control={<Radio />} label="원화" />
              </RadioGroup>
            </FormControl>

            <DropZone single={singleUpdate} setSingle={setSingleUpdate} />
            <TextField
              size="small"
              fullWidth
              label={"삭제하실 경우에만 '삭제'를 입력해주세요."}
              margin="dense"
              variant="outlined"
              {...deleteCheck}
            />
            <Button fullWidth variant="contained" type="submit" color="primary">
              수정
            </Button>
            <Button
              onClick={async () => {
                if (deleteCheck.value !== "삭제") {
                  return modal.setState("입력창에 '삭제' 를 입력해주세요. (실수방지)");
                }

                const { data } = await Axios.post(
                  `${HOST}/admin/coin/delete`,
                  { name: dialog.name, ticker: dialog.ticker },
                  httpHeader()
                );
                getConfig();
                setDialog(false);
              }}
              color="secondary"
              fullWidth
              variant="contained"
              type="button"
              style={{ marginTop: 10 }}
            >
              삭제
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {}} color="primary"></Button>
          <Button onClick={() => {}} color="primary"></Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item lg={8} md={8} xs={12}>
          <Card className={classes.card}>
            <CardHeader title={"코인 추가"} subheader={""} />
            <CardContent>
              <form onSubmit={onSubmitAddCoin}>
                <TextField
                  size="small"
                  fullWidth
                  label={"코인 풀 네임 (e.g. BitCoin)"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...coinName}
                />

                <TextField
                  size="small"
                  fullWidth
                  label={"코인 티커 (e.g. BTC)"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...coinTicker}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={"컨트랙트 주소"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...coinContract}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={"기본 설정 마켓캡 ($)"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...defaultMarketcap}
                />
                <FormControl style={{ marginTop: 10 }}>
                  <FormLabel id="demo-row-radio-buttons-group-label">가격 표기 방법</FormLabel>
                  <Typography variant="caption">(원화 선택시 달러 가격에서 원화로 환산된 금액으로 표기됨)</Typography>
                  <RadioGroup
                    onChange={({ target: { value } }) => {
                      setCurrencyType(value);
                    }}
                    value={currencyType}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel value="dollar" control={<Radio />} label="달러" />
                    <FormControlLabel value="won" control={<Radio />} label="원화" />
                  </RadioGroup>
                </FormControl>
                <DropZone single={single} setSingle={setSingle} />
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                <Button color="primary" fullWidth size="large" type="submit" variant="contained">
                  {"코인 추가"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Card className={classes.card}>
            <CardHeader title={"설정 변경"} subheader={""} />
            <CardContent>
              <form onSubmit={onSubmitConfig}>
                <TextField
                  size="small"
                  fullWidth
                  label={"가맹점 결제 수수료 (%)"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...payFee}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={"스테이킹 일(Day)수"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...stakingDay}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={"스테이킹 보상 %"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...stakingPer}
                />
                <TextField
                  size="small"
                  fullWidth
                  label={"최소 스테이킹 수량"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  {...stakingMin}
                />

                <TextField
                  size="small"
                  fullWidth
                  label={"스테이킹 상태"}
                  margin="dense"
                  variant="outlined"
                  required={true}
                  helperText={"스테이킹 상태, 1=스테이킹가능, 0=스테이킹불가"}
                  {...stakingAvailable}
                />

                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                <Button color="primary" fullWidth size="large" type="submit" variant="contained">
                  {"설정 저장"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <Card className={classes.card}>
            <CardHeader title={"코인 관리"} subheader={""} />
            <CardContent>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{"이름"}</TableCell>
                      <TableCell align="left">{"티커"}</TableCell>
                      <TableCell align="left">{"심볼"}</TableCell>
                      <TableCell align="left">{"설정된 마켓캡($)"}</TableCell>
                      <TableCell align="left">{"가격 표기 방식"}</TableCell>
                      <TableCell align="left">{"관리"}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {token &&
                      token.map(({ name, ticker, symbol_image, default_marketcap, currency_type }, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell align="left">{name}</TableCell>
                            <TableCell align="left">{ticker}</TableCell>
                            <TableCell align="left">
                              <img style={{ width: 24, height: 24 }} src={HOST + "/" + symbol_image} />
                            </TableCell>
                            <TableCell align="left">
                              {default_marketcap ? Number(default_marketcap) + " $" : " - "}
                            </TableCell>
                            <TableCell align="left">{currency_type}</TableCell>
                            <TableCell>
                              <Button
                                onClick={() => {
                                  defaultMarketcapUpdate.setValue(Number(default_marketcap));
                                  setDialog({
                                    name,
                                    ticker,
                                    symbol_image,
                                    default_marketcap,
                                  });
                                }}
                                color="primary"
                                size="small"
                                variant="contained"
                              >
                                {"관리"}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Card className={classes.card}>
            <CardHeader title={"엑셀 가입링크 보내기"} subheader={""} />
            <CardContent>
              <form onSubmit={onSubmitExcel}>
                <DropZoneExcel single={excel} setSingle={setExcel} />
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                <Button color="primary" fullWidth size="large" type="submit" variant="contained">
                  {"엑셀 가입링크 전송"}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  ) : (
    <Loader />
  );
};

export default Page;
