import React, { useContext } from "react";
import styled from "styled-components";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "reactstrap";

import { QrCodeIcon } from "../Icons";
import Theme from "../../Styles/Theme";
import { languagePack } from "../../Utils/language";
import globalContext from "../../Hooks/globalContext";
import Background from "../../Resources/Images/background.png";

const InfoBox = styled.div`
  margin-top: 10px;
  display: flex;
  position: relative;
  width: 92.5%;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: #fafafa;
  height: 135px;
  padding: 25px;
  background-image: url(${Background});
  background-size: cover;
  cursor: pointer;
  :hover {
    opacity: 0.75;
  }
`;

const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoTitle = styled.div`
  font-size: 21px;
  color: white;
`;
const Address = styled.div`
  font-size: 13px;
  word-break: break-all;
  color: white;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddressBox = styled.div`
  margin: 7.5px 0px;
  padding: 7.5px 0px;
  text-align: center;
  white-space: normal;
  word-break: break-all;
  font-size: 12px;
  width: 100%;
`;

const PopupBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CloseBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 25px;
  cursor: pointer;
`;

const MyWalletInfo = () => {
  const { language, user, modal } = useContext(globalContext);

  const onCopy = () => {
    modal.setState(null);
  };

  const boxOnClick = () => {
    modal.setState(
      <Popup>
        <QRCode value={user.state.wallet_address} size={192} />
        <AddressBox>{user.state.wallet_address}</AddressBox>
        <CopyToClipboard text={user.state.wallet_address} onCopy={onCopy}>
          <Button style={{ backgroundColor: Theme.mainColor, border: 0 }} type="button">
            {languagePack("클립보드로 복사", language.state)}
          </Button>
        </CopyToClipboard>
      </Popup>
    );
  };

  return (
    <InfoBox onClick={boxOnClick}>
      <QrCodeIcon size={20} color={`white`} />
      <InfoTitle>Wallet Address</InfoTitle>
      <Address>{user.state.wallet_address}</Address>
    </InfoBox>
  );
};

export default MyWalletInfo;
