import React from "react";
import styled from "styled-components";

import { HomeIcon, HistoryIcon, SendIcon, SettingIcon, StakingIcon } from "../Icons";
import { useHistory } from "react-router-dom";
import Theme from "../../Styles/Theme";

const FooterWrapper = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #eaeaea;
  justify-content: space-around;
`;

const Element = styled.div`
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :not(:last-child) {
    border-right: #efefef;
  }
  cursor: pointer;
  :hover {
    opacity: 0.75;
  }
`;

const Label = styled.div`
  font-size: 10px;
  color: ${(props) => props.theme.darkGrey};
`;

const IconWrapper = styled.div`
  height: 25px;
`;

const Node = ({ path }) => {
  const history = useHistory();
  const onClick = () => {
    history.push("/" + path);
  };
  const name = () => {
    let converted = "";
    try {
      for (let i = 0; i < path.length; i++) {
        if (i === 0) {
          converted += path[i].toUpperCase();
        } else {
          converted += path[i];
        }
      }
      return converted;
    } catch (e) {
      return path;
    }
  };

  return (
    <>
      {path === "home" && (
        <>
          {history.location.pathname === "/" ? (
            <Element>
              <IconWrapper>
                <HomeIcon color={Theme.mainColor} size={25} />
              </IconWrapper>
              <Label style={{ color: Theme.mainColor }}>{name()}</Label>
            </Element>
          ) : (
            <Element onClick={onClick}>
              <IconWrapper>
                <HomeIcon color="grey" size={25} />
              </IconWrapper>
              <Label>{name()}</Label>
            </Element>
          )}
        </>
      )}
      {path === "history" && (
        <>
          {history.location.pathname === "/history" ? (
            <Element>
              <IconWrapper>
                <HistoryIcon color={Theme.mainColor} size={19} />
              </IconWrapper>
              <Label style={{ color: Theme.mainColor }}>{name()}</Label>
            </Element>
          ) : (
            <Element onClick={onClick}>
              <IconWrapper>
                <HistoryIcon color="grey" size={19} />
              </IconWrapper>
              <Label>{name()}</Label>
            </Element>
          )}
        </>
      )}
      {path === "send" && (
        <>
          {history.location.pathname === "/send" ? (
            <Element>
              <IconWrapper>
                <SendIcon color={Theme.mainColor} size={19} />
              </IconWrapper>
              <Label style={{ color: Theme.mainColor }}>{name()}</Label>
            </Element>
          ) : (
            <Element onClick={onClick}>
              <IconWrapper>
                <SendIcon color="grey" size={19} />
              </IconWrapper>
              <Label>{name()}</Label>
            </Element>
          )}
        </>
      )}
      {path === "staking" && (
        <>
          {history.location.pathname === "/staking" ? (
            <Element>
              <IconWrapper>
                <StakingIcon color={Theme.mainColor} size={19} />
              </IconWrapper>
              <Label style={{ color: Theme.mainColor }}>{name()}</Label>
            </Element>
          ) : (
            <Element onClick={onClick}>
              <IconWrapper>
                <StakingIcon color="grey" size={19} />
              </IconWrapper>
              <Label>{name()}</Label>
            </Element>
          )}
        </>
      )}
      {path === "more" && (
        <>
          {history.location.pathname === "/more" ? (
            <Element>
              <IconWrapper>
                <SettingIcon color={Theme.mainColor} size={19} />
              </IconWrapper>
              <Label style={{ color: Theme.mainColor }}>{name()}</Label>
            </Element>
          ) : (
            <Element onClick={onClick}>
              <IconWrapper>
                <SettingIcon color="grey" size={19} />
              </IconWrapper>
              <Label>{name()}</Label>
            </Element>
          )}
        </>
      )}
    </>
  );
};

const Footer = () => (
  <FooterWrapper>
    <Node path="home" />
    <Node path="history" />
    <Node path="send" />
    {/* <Node path="staking" /> */}
    <Node path="more" />
  </FooterWrapper>
);

export default Footer;
