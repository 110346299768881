import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Popup from "reactjs-popup";
import Select from "react-select";
import QRCode from "qrcode.react";
import saveAs from "file-saver";

import { dateConvertor, ToFixed, numberWithCommas } from "../../../Utils/util";
import Theme from "../../../Styles/Theme";
import Button from "../../Common/DefaultButton";
import GlobalContext from "../../../Hooks/globalContext";
import {
  HOST,
  CHAIN_ID,
  CHAIN_NAME,
  NUMBER_REGEX,
  GAS_PRICE_API,
  ETHERSCAN_TX_URL,
  ETHERSCAN_ADDRESS_URL,
  AMOUNT_REGEX,
} from "../../../Utils/constants";
import { toast } from "react-toastify";
import useState from "../../../Hooks/useState";
import { languagePack } from "../../../Utils/language";
import Input from "../../Common/Input";
import useInput from "../../../Hooks/useInput";
import { ClipLoader } from "react-spinners";
import customStyles from "../../../Styles/SelectStyle";
import moment from "moment";

const ButtonWrapper = styled.div`
  display: flex;
`;

const UpdateButton = styled.div`
  background-color: cadetblue;
  color: white;
  width: auto;
  min-width: 60px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
    opacity: 0.75;
  }
  :not(:last-child) {
    margin-right: 5px;
  }
`;

const UpdateLongButton = styled(UpdateButton)`
  width: 100px;
`;

const ShortRow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
`;

const DeleteButton = styled(UpdateButton)`
  background-color: ${(props) => props.theme.navbar};
`;

const DefaultButton = styled(UpdateButton)`
  background-color: ${(props) => props.theme.button};
  width: 65px;
`;

const DeleteForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: ${(props) => props.theme.black};
`;

const DeleteLabel = styled.div`
  font-size: 17px;
  margin-bottom: 10px;
`;

const RedLabel = styled.span`
  color: red;
`;

const BlueLabel = styled.span`
  color: blue;
`;

const StrongSpan = styled.span`
  font-weight: bold;
`;

const QnaRow = styled.div`
  border-bottom: 1px solid grey;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 13px;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const OrangeButton = styled(UpdateButton)`
  background-color: orange;
`;

const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const OptionSpan = styled.span`
  margin-left: 5px;
`;

const SelectWrapper = styled.div`
  padding: 5px 10px;
  width: 100%;
`;

const ColumnFactory = (params) => {
  const { user, httpHeader, getBalance, modal, web3, getTxCount, contract, language, sendEth, sendToken } =
    useContext(GlobalContext);
  const pointInput = useInput({ defaultValue: "", maxLength: 10 });
  const shopNameInput = useInput({ defaultValue: "", maxLength: 40 });
  const passwordInput = useInput({ defaultValue: "", maxLength: 40 });

  const lockAmount = useInput({ defaultValue: "", maxLength: 15, regex: AMOUNT_REGEX });
  const lockDay = useInput({ defaultValue: "", maxLength: 15, regex: NUMBER_REGEX });
  const loading = useState(null);

  // const defaultValue = useState(null);
  const selected = useState(null);

  const referralCodeInput = useInput({ defaultValue: "", maxLength: 14 });
  const recommenderUpdateOnClick = async ({ close, item }) => {
    if (referralCodeInput.value.length < 2) {
      modal.setState("너무 짧습니다.");
      return;
    }

    const requestBody = {
      action: "recommender",
      userId: item.id,
      userReferralCode: item.referral_code,
      referralCode: referralCodeInput.value,
    };
    const { data } = await axios.post(`${HOST}/admin/users/crud`, requestBody, httpHeader());

    if (data.status) {
      referralCodeInput.setValue("");
      modal.setState("성공적으로 처리되었습니다.");
      params.reset({ selected: params.nowPage.state });
      close();
    } else {
      switch (data.code) {
        case "invalid":
          modal.setState("잘못된 추천인 코드입니다.");
          break;
        case "me":
          modal.setState("유저 본인을 추천할 수 없습니다.");
          break;
        default:
          modal.setState("서버 오류입니다. 잠시 후에 다시 시도해주세요.");
          break;
      }
    }
  };
  return {
    user: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "이메일",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "지갑주소",
        dataIndex: "wallet_address",
        key: "wallet_address",
      },
      {
        title: "추천코드",
        dataIndex: "referral_code",
        key: "referral_code",
      },
      {
        title: "추천인",
        render: (item) => {
          return (
            <>
              {item.recommend ? (
                item.recommend
              ) : (
                <ButtonWrapper>
                  <Popup
                    trigger={<UpdateButton>입력</UpdateButton>}
                    modal={true}
                    contentStyle={{
                      padding: 25,
                      width: 300,
                      border: 0,
                      borderRadius: 10,
                    }}
                  >
                    {(close) => (
                      <DeleteForm>
                        <DeleteLabel>추천인 코드를 입력해주세요.</DeleteLabel>
                        <Input style={{ marginBottom: 10 }} {...referralCodeInput} placeholder="추천인 코드" />
                        <Button
                          onClick={recommenderUpdateOnClick.bind(null, { close, item })}
                          text="등록"
                          style={{ padding: "10px 15px" }}
                        />
                      </DeleteForm>
                    )}
                  </Popup>
                </ButtonWrapper>
              )}
            </>
          );
        },
      },
      {
        title: "가입일",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{dateConvertor(new Date(item))}</>;
        },
      },
      {
        title: "이메일인증",
        dataIndex: "email_confirmed",
        key: "email_confirmed",
        render: (item) => {
          return <>{item === 0 ? "X" : "O"}</>;
        },
      },

      {
        title: "관리",
        render: (item) => {
          const shopOnClick = async (close) => {
            if (shopNameInput.value.length < 2) {
              modal.setState("잘못된 가맹점명입니다.");
              return;
            }

            const response = await axios.post(
              `${HOST}/admin/shop/register`,
              { userId: item.id, shopName: shopNameInput.value },
              httpHeader()
            );
            close();
            params.reset({ selected: params.nowPage.state });
            shopNameInput.setValue("");
            modal.setState("해당 유저에 가맹점 권한을 부여했습니다.");
          };

          const deleteOnClick = async (close) => {
            const requestBody = {
              userId: item.id,
              referralCode: item.referralCode,
              action: "delete",
            };
            const { data } = await axios.post(`${HOST}/admin/users/crud`, requestBody, httpHeader());
            close();
            params.reset({ selected: params.nowPage.state });
          };

          const updateOnClick = async (close) => {
            if (passwordInput.value === "" || passwordInput.value.length < 2) {
              modal.setState("입력값이 없거나 짧습니다.");
              return;
            }
            const requestBody = {
              userId: item.id,
              password: passwordInput.value,
              action: "update",
            };
            const { data } = await axios.post(`${HOST}/admin/users/crud`, requestBody, httpHeader());
            close();
            params.reset({ selected: params.nowPage.state });
          };

          const lockOnClick = async (close) => {
            if (lockAmount.value === "" || lockDay.value === "") {
              modal.setState("입력값이 없습니다.");
              return;
            }

            if (isNaN(Number(lockAmount.value)) || isNaN(Number(lockDay.value))) {
              modal.setState("숫자를 입력해주세요.");
              return;
            }

            if (!selected.state) {
              modal.setState("락업할 토큰 타입을 선택해주세요.");
              return;
            }

            const requestBody = {
              userId: item.id,
              userEmail: item.email,
              tokenType: selected.state,
              amount: Number(lockAmount.value),
              day: Number(lockDay.value),
            };

            const { data } = await axios.post(`${HOST}/admin/users/lock`, requestBody, httpHeader());
            if (data.status) {
              close();
              lockAmount.setValue("");
              lockDay.setValue("");
              params.reset({ selected: params.nowPage.state });
              modal.setState("락업이 완료되었습니다.");
            }
          };
          const pointOnClick = async (close) => {
            if (pointInput.value === "" || pointInput.value === 0 || isNaN(Number(pointInput.value))) {
              return modal.setState("잘못된 입력입니다.");
            }
            if (!selected.state) {
              return modal.setState("증감시킬 자산을 선택해주세요.");
            }

            const requestBody = { referralCode: item.referral_code, amount: pointInput.value, type: selected.state };
            const { data } = await axios.post(`${HOST}/admin/users/point`, requestBody, httpHeader());
            modal.setState("성공적으로 처리되었습니다.");
            close();
            pointInput.setValue("");
            params.reset({ selected: params.nowPage.state });
          };

          const handleChange = ({ value }) => {
            selected.setState(value);
          };

          const depositOnPress = async (close) => {
            if (!selected.state) {
              modal.setState("입금확인할 토큰 타입을 선택해주세요.");
              return;
            }
            const { data } = await axios.post(
              `${HOST}/admin/user/charge`,
              { ticker: selected.state, referral_code: item.referral_code },
              httpHeader()
            );
            params.reset({ selected: params.nowPage.state });
            close();
            if (data.status) {
              return modal.setState("입금 확인을 요청하였습니다. 입금 확인에는 최대 1시간이 소요됩니다.");
            } else {
              return modal.setState("이미 입금 확인을 요청하였습니다. 잠시만 기다려주세요.");
            }
          };

          return (
            <ButtonWrapper>
              <Popup
                trigger={
                  <OrangeButton style={{ color: "white", minWidth: 70, backgroundColor: "blue" }}>
                    입금 확인
                  </OrangeButton>
                }
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <SelectWrapper>
                      <Select
                        onChange={handleChange}
                        isClearable={false}
                        isSearchable={false}
                        styles={customStyles}
                        placeholder={"입금확인할 토큰"}
                        options={params.token.map(({ name, ticker, symbol_image }, index) => {
                          return {
                            key: index,
                            value: ticker,
                            label: (
                              <OptionWrapper>
                                <img src={HOST + "/" + symbol_image} style={{ width: 16, height: 16 }} />
                                <OptionSpan>
                                  {name.toUpperCase()} ({ticker})
                                </OptionSpan>
                              </OptionWrapper>
                            ),
                          };
                        })}
                      />
                    </SelectWrapper>

                    <Button
                      onClick={depositOnPress.bind(null, close)}
                      text={"입금 확인"}
                      style={{ padding: "10px 15px", backgroundColor: Theme.mainColor }}
                    />
                  </DeleteForm>
                )}
              </Popup>

              {!item.shop_id && (
                <Popup
                  trigger={
                    <button
                      style={{ marginRight: 5, borderRadius: 5, minWidth: 80, backgroundColor: "purple", padding: 5 }}
                      type="button"
                    >
                      가맹점 등록
                    </button>
                  }
                  modal={true}
                  contentStyle={{
                    padding: 25,
                    width: 300,
                    border: 0,
                    borderRadius: 10,
                  }}
                >
                  {(close) => (
                    <DeleteForm>
                      <Input
                        placeholder="가맹점 이름"
                        style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                        {...shopNameInput}
                      />
                      <Button
                        onClick={shopOnClick.bind(null, close)}
                        block={true}
                        text="등록"
                        style={{ padding: "10px 15px", backgroundColor: Theme.mainColor }}
                      />
                    </DeleteForm>
                  )}
                </Popup>
              )}
              <Popup
                trigger={
                  <button
                    style={{ marginRight: 5, borderRadius: 5, minWidth: 100, backgroundColor: "cyan", padding: 5 }}
                    type="button"
                  >
                    비밀번호 변경
                  </button>
                }
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <Input
                      placeholder="변경할 비밀번호"
                      style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                      {...passwordInput}
                      type="password"
                    />
                    <Button
                      onClick={updateOnClick.bind(null, close)}
                      block={true}
                      text="등록"
                      style={{ padding: "10px 15px", backgroundColor: Theme.mainColor }}
                    />
                  </DeleteForm>
                )}
              </Popup>
              <Popup
                trigger={<OrangeButton style={{ minWidth: 90 }}>토큰 에어드롭</OrangeButton>}
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <DeleteLabel>토큰 에어드롭</DeleteLabel>
                    <DeleteLabel style={{ color: Theme.darkGrey, fontSize: 13 }}>
                      유저가 가진 토큰을 증가 혹은 감소시킬 수 있습니다. 감소 시킬 경우에는 숫자에 -(마이너스)를 꼭
                      붙여주세요. (예:-100)
                    </DeleteLabel>
                    <Input style={{ marginBottom: 10 }} {...pointInput} placeholder="숫자 입력" />
                    <div style={{ width: "100%", marginBottom: 10 }}>
                      <Select
                        onChange={handleChange}
                        isClearable={false}
                        isSearchable={false}
                        styles={customStyles}
                        placeholder={"토큰 종류"}
                        options={params.token.map(({ name, ticker, symbol_image }, index) => {
                          return {
                            key: index,
                            value: ticker,
                            label: (
                              <OptionWrapper>
                                <img src={HOST + "/" + symbol_image} style={{ width: 16, height: 16 }} />
                                <OptionSpan>
                                  {name.toUpperCase()} ({ticker})
                                </OptionSpan>
                              </OptionWrapper>
                            ),
                          };
                        })}
                      />
                    </div>
                    <Button onClick={pointOnClick.bind(null, close)} text="확인" style={{ padding: "10px 15px" }} />
                  </DeleteForm>
                )}
              </Popup>
              <Popup
                trigger={<OrangeButton style={{ backgroundColor: "green" }}>{"락업"}</OrangeButton>}
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <SelectWrapper>
                      <Select
                        onChange={handleChange}
                        isClearable={false}
                        isSearchable={false}
                        styles={customStyles}
                        placeholder={"락업할 토큰"}
                        options={params.token.map(({ name, ticker, symbol_image }, index) => {
                          return {
                            key: index,
                            value: name,
                            label: (
                              <OptionWrapper>
                                <img src={HOST + "/" + symbol_image} style={{ width: 16, height: 16 }} />
                                <OptionSpan>
                                  {name.toUpperCase()} ({ticker})
                                </OptionSpan>
                              </OptionWrapper>
                            ),
                          };
                        })}
                      />
                    </SelectWrapper>
                    <Input
                      placeholder={"락업 갯수"}
                      style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                      {...lockAmount}
                    />
                    <Input
                      placeholder={"락업 시간 (일 단위)"}
                      style={{ marginBottom: 10, color: "black", backgroundColor: "white" }}
                      {...lockDay}
                    />
                    <Button
                      onClick={lockOnClick.bind(null, close)}
                      text={"락업"}
                      style={{ padding: "10px 15px", backgroundColor: Theme.mainColor }}
                    />
                  </DeleteForm>
                )}
              </Popup>
              <Popup
                trigger={<DeleteButton>삭제</DeleteButton>}
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <DeleteLabel>정말 삭제하시겠습니까?</DeleteLabel>
                    <Button onClick={deleteOnClick.bind(null, close)} text="삭제" style={{ padding: "10px 15px" }} />
                  </DeleteForm>
                )}
              </Popup>
            </ButtonWrapper>
          );
        },
      },
    ],
    swapList: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "유저 메일",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "스왑 신청 금액",
        key: "from",
        render: (item) => {
          return (
            <>
              {numberWithCommas(Number(item.amount_send).toFixed(4))}
              {` ${item.ticker_send}`}
            </>
          );
        },
      },
      {
        title: "스왑 지급 금액",
        key: "from",
        render: (item) => {
          return (
            <>
              {numberWithCommas(Number(item.amount_receive).toFixed(4))}
              {` ${item.ticker_receive}`}
            </>
          );
        },
      },
      {
        title: "신청일",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
    ],
    balance: params.token
      ? [
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          ...params.token.map((item, index) => {
            return {
              title: item.ticker,
              dataIndex: item.ticker,
              key: item.ticker,
            };
          }),
        ]
      : [],
    shopList: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "가맹점 관리자 Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "가맹점명",
        dataIndex: "shop_name",
        key: "shop_name",
      },
      {
        title: "등록일",
        dataIndex: "created_at",
        key: "created_at",
        render: (created_at) => <>{dateConvertor(new Date(created_at))}</>,
      },
      {
        title: "관리",
        render: (item) => {
          const content = {
            shopName: item.shop_name,
            shopId: item.id,
            timeStamp: new Date(item.created_at).getTime(),
          };

          const toggle = async () => {
            const requestBody = {
              id: item.id,
              toggle: item.is_freezed,
              action: "toggle",
            };
            const { data } = await axios.post(`${HOST}/admin/shop`, requestBody, httpHeader());
            params.reset({ selected: params.nowPage.state });
          };

          const deleteOnClick = async () => {
            const requestBody = {
              id: item.id,
              userId: item.master_id,
              action: "delete",
            };
            const { data } = await axios.post(`${HOST}/admin/shop`, requestBody, httpHeader());
            params.reset({ selected: params.nowPage.state });
          };

          return (
            <ButtonWrapper>
              <Popup
                trigger={<UpdateButton style={{ minWidth: 90 }}>가맹점 QR코드</UpdateButton>}
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <QRCode id={"resetCanvas" + item.id} value={JSON.stringify(content)} size={192} />
                    <Button
                      onClick={() => {
                        const canvasSave = document.getElementById("resetCanvas" + item.id);
                        canvasSave.toBlob((blob) => {
                          saveAs(blob, item.shop_name + "_qr.png");
                        });
                      }}
                      text="저장"
                      style={{ padding: "10px 15px", marginTop: 15 }}
                    />
                  </DeleteForm>
                )}
              </Popup>

              {item.is_freezed === 0 ? (
                <OrangeButton onClick={toggle} style={{ minWidth: 75 }}>
                  가맹점 정지
                </OrangeButton>
              ) : (
                <OrangeButton onClick={toggle} style={{ minWidth: 75 }}>
                  정지 해제
                </OrangeButton>
              )}
              <Popup
                trigger={
                  <DeleteButton style={{ minWidth: 75 }} onClick={toggle}>
                    가맹점 삭제
                  </DeleteButton>
                }
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <DeleteLabel>정말 삭제하시겠습니까?</DeleteLabel>
                    <Button onClick={deleteOnClick.bind(null, close)} text="삭제" style={{ padding: "10px 15px" }} />
                  </DeleteForm>
                )}
              </Popup>
            </ButtonWrapper>
          );
        },
      },
    ],
    withdraw: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "유저 이메일",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "신청일",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "토큰 타입",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "신청 금액",
        dataIndex: "amount",
        key: "amount",
        render: (item) => {
          return Number(item);
        },
      },
      {
        title: "상태",
        dataIndex: "status",
        key: "status",
        render: (item) => {
          return (
            <>
              {item === 0 && <RedLabel>{"대기중"}</RedLabel>}
              {item === 1 && <BlueLabel>{"출금완료"}</BlueLabel>}
              {item === 2 && <RedLabel>{"거절됨"}</RedLabel>}
            </>
          );
        },
      },
      // {
      //   title: "출금 해시",
      //   dataIndex: "hash",
      //   key: "hash",
      //   render: (item) => {
      //     return item ? (
      //       <ButtonWrapper>
      //         <OrangeButton onClick={() => window.open(`${ETHERSCAN_TX_URL}${item}`)}>확인</OrangeButton>
      //       </ButtonWrapper>
      //     ) : (
      //       "-"
      //     );
      //   },
      // },
      {
        title: "출금/승인/거절일",
        dataIndex: "confirmed_at",
        key: "confirmed_at",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "출금해시",
        dataIndex: "hash",
        key: "hash",
        render: (item) => {
          return (
            <>
              {item ? (
                <ButtonWrapper>
                  <OrangeButton onClick={() => window.open(ETHERSCAN_TX_URL + item)}>확인</OrangeButton>
                </ButtonWrapper>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        title: "수취인 지갑 주소",
        dataIndex: "withdraw_address",
        key: "withdraw_address",
        render: (item) => {
          return (
            <ButtonWrapper>
              <OrangeButton onClick={() => window.open(`${ETHERSCAN_ADDRESS_URL}${item}`)}>확인</OrangeButton>
            </ButtonWrapper>
          );
        },
      },
      {
        title: "관리",
        render: (item) => {
          const rejectOnClick = async (close) => {
            const requestBody = {
              withdrawId: item.id,
            };
            const { data } = await axios.post(`${HOST}/admin/withdraw/reject`, requestBody, httpHeader());
            close();
            params.reset({ selected: 0, init: true });
          };

          const confirmOnClick = async (close) => {
            if (!web3.utils.isAddress(params.walletInput.value)) {
              return modal.setState(languagePack("올바른 지갑주소가 아닙니다.", language.state));
            }

            if (params.privInput.value === "" || params.privInput.value.length !== 64) {
              return modal.setState("올바른 비밀 키를 입력하세요.");
            }
            loading.setState(true);

            const privateKey = Buffer.from(params.privInput.value, "hex");
            const { data: fee } = await axios.post(GAS_PRICE_API);

            if (item.type.toUpperCase() === "ETH") {
              const sendForm = {
                address: params.walletInput.value,
                fee: fee.fastest,
                to: item.withdraw_address,
                amount: item.amount,
                isLoading: loading,
                txCount: params.txCount,
                privateKey,
                confirm: async (hash) => {
                  const requestBody = {
                    withdrawId: item.id,
                    hash,
                  };
                  const { data } = await axios.post(`${HOST}/admin/withdraw/confirm`, requestBody, httpHeader());
                  close();
                  params.reset({ selected: 0, init: true });
                },
                reject: (error) => {
                  modal.setState(error);
                },
              };
              await sendEth(sendForm);
            } else {
              const { data } = await axios.post(
                `${HOST}/api/tokens`,
                { ticker: item.type.toUpperCase() },
                httpHeader()
              );
              const sendForm = {
                address: params.walletInput.value,
                fee: fee.fastest,
                to: item.withdraw_address,
                amount: item.amount,
                txCount: params.txCount,
                isLoading: loading,
                confirm: async (hash) => {
                  const requestBody = {
                    withdrawId: item.id,
                    hash,
                  };
                  const { data } = await axios.post(`${HOST}/admin/withdraw/confirm`, requestBody, httpHeader());
                  close();
                  params.reset({ selected: 0, init: true });
                },
                reject: (error) => {
                  modal.setState(error);
                },
                symbol: item.type,
                privateKey,
              };

              await sendToken(sendForm);
            }
          };

          return item.status === 0 ? (
            <ButtonWrapper>
              <Popup
                trigger={<OrangeButton style={{ backgroundColor: "green" }}>{"승인"}</OrangeButton>}
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <DeleteLabel>유저의 출금 기록을 승인합니다.</DeleteLabel>
                    <Button
                      disabled={loading.state}
                      onClick={confirmOnClick.bind(null, close)}
                      text={"승인"}
                      style={{ padding: "10px 15px", backgroundColor: Theme.mainColor }}
                    />
                  </DeleteForm>
                )}
              </Popup>
              <Popup
                trigger={<DeleteButton>거절</DeleteButton>}
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <DeleteLabel>
                      출금 기록을 거절합니다. 유저의 지갑 내부 토큰 갯수가 신청 금액만큼 다시 증가합니다.
                    </DeleteLabel>
                    <Button
                      onClick={rejectOnClick.bind(null, close)}
                      text={"거절"}
                      style={{ padding: "10px 15px", backgroundColor: Theme.mainColor }}
                    />
                  </DeleteForm>
                )}
              </Popup>
            </ButtonWrapper>
          ) : (
            "-"
          );
        },
      },
    ],
    lockupList: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "락업 갯수",
        dataIndex: "locked_amount",
        key: "locked_amount",
        render: (item) => {
          return <>{Number(item)}</>;
        },
      },
      {
        title: "토큰 타입",
        dataIndex: "token_type",
        key: "token_type",
      },
      {
        title: "날짜",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "강제 해제상태",
        dataIndex: "status",
        key: "status",
        render: (item) => {
          return <>{item === 0 ? "-" : <RedLabel>강제 해제됨</RedLabel>}</>;
        },
      },
      {
        title: "락업 해제일",
        dataIndex: "release_time",
        key: "release_time",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "유저 번호",
        dataIndex: "user_id",
        key: "user_id",
      },
      {
        title: "유저 이메일",
        dataIndex: "user_email",
        key: "user_email",
      },
      {
        title: "관리",
        render: (item) => {
          const toggle = async () => {
            const requestBody = {
              id: item.id,
              toggle: item.status,
            };
            const { data } = await axios.post(`${HOST}/admin/lockup/toggle`, requestBody, httpHeader());

            params.reset({ selected: params.nowPage.state });
          };

          const deleteOnClick = async () => {
            const requestBody = {
              id: item.id,
              toggle: item.status,
              delete: true,
            };
            const { data } = await axios.post(`${HOST}/admin/lockup/toggle`, requestBody, httpHeader());

            params.reset({ selected: params.nowPage.state });
          };

          return (
            <ButtonWrapper>
              {item.status === 0 ? (
                <OrangeButton style={{ minWidth: 100 }} onClick={toggle}>
                  락업 강제 해제
                </OrangeButton>
              ) : (
                <DeleteButton style={{ minWidth: 100 }} onClick={toggle}>
                  재 락업
                </DeleteButton>
              )}
              <DeleteButton style={{ minWidth: 100 }} onClick={deleteOnClick}>
                삭제
              </DeleteButton>
            </ButtonWrapper>
          );
        },
      },
    ],
    shopExchange: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "금액",
        dataIndex: "amount",
        key: "amount",
        render: (item) => {
          return Number(item);
        },
      },
      {
        title: "가맹점 이름",
        dataIndex: "shop_name",
        key: "shop_name",
        render: (item) => {
          return <>{item ? item : "-"}</>;
        },
      },
      {
        title: "가맹점주 E-Mail",
        dataIndex: "receive_email",
        key: "receive_email",
        render: (item) => {
          return <>{item ? item : "-"}</>;
        },
      },
      {
        title: "결제일",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{item ? moment(item).format("YYYY-MM-DD HH:mm:ss") : "-"}</>;
        },
      },
      {
        title: "환전 신청일",
        dataIndex: "exchange_request_at",
        key: "exchange_request_at",
        render: (item) => {
          return <>{item ? moment(item).format("YYYY-MM-DD HH:mm:ss") : "-"}</>;
        },
      },
      {
        title: "환전 승인일",
        dataIndex: "company_payed_at",
        key: "company_payed_at",
        render: (item) => {
          return <>{item ? moment(item).format("YYYY-MM-DD HH:mm:ss") : "-"}</>;
        },
      },
      {
        title: "관리",
        render: (item) => {
          const toggle = async () => {
            const requestBody = {
              id: item.id,
              toggle: item.company_payed_at,
            };
            const { data } = await axios.post(`${HOST}/admin/pay/toggle`, requestBody, httpHeader());

            params.reset({ selected: params.nowPage.state });
          };

          return (
            <ButtonWrapper>
              {item.company_payed_at ? (
                <OrangeButton style={{ minWidth: 100 }} onClick={toggle}>
                  승인 취소
                </OrangeButton>
              ) : (
                <DeleteButton style={{ minWidth: 100, backgroundColor: "green" }} onClick={toggle}>
                  승인
                </DeleteButton>
              )}
            </ButtonWrapper>
          );
        },
      },
    ],
    sendLog: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "전송일",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "전송 타입",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "금액",
        dataIndex: "amount",
        key: "amount",
        render: (item) => {
          return Number(item);
        },
      },
      {
        title: "보낸사람 이메일",
        dataIndex: "send_email",
        key: "send_email",
      },
      {
        title: "받는사람 이메일",
        dataIndex: "receive_email",
        key: "receive_email",
      },
      {
        title: "보낸사람 지갑주소",
        dataIndex: "send_address",
        key: "send_address",
      },
      {
        title: "받는사람 지갑주소",
        dataIndex: "receive_address",
        key: "receive_address",
      },
      {
        title: "결제한 가맹점",
        dataIndex: "shop_name",
        key: "shop_name",
        render: (item) => {
          return <>{item ? item : "-"}</>;
        },
      },
    ],
    stakingList: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "스테이킹된 갯수",
        dataIndex: "staking_amount",
        key: "staking_amount",
        render: (item) => {
          return <>{Number(item)}</>;
        },
      },
      {
        title: "날짜",
        dataIndex: "created_at",
        key: "created_at",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "스테이킹 해제일",
        dataIndex: "release_time",
        key: "release_time",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
      {
        title: "유저 이메일",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "적용된 스테이킹 일수",
        dataIndex: "staking_day",
        key: "staking_day",
      },
      {
        title: "적용된 스테이킹 %",
        dataIndex: "staking_per",
        key: "staking_per",
      },

      {
        title: "지급 상태",
        dataIndex: "status",
        key: "status",
        render: (item) => {
          return <>{item === 0 ? <RedLabel>{"미지급"}</RedLabel> : <BlueLabel>{"지급"}</BlueLabel>}</>;
        },
      },
      {
        title: "스테이킹보상 지급일",
        dataIndex: "reward_time",
        key: "reward_time",
        render: (item) => {
          return <>{item ? dateConvertor(new Date(item)) : "-"}</>;
        },
      },
    ],
    notice: [
      {
        title: "번호",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "제목",
        dataIndex: "title_kor",
        key: "title_kor",
      },
      {
        title: "작성자",
        dataIndex: "writer",
        key: "writer",
      },
      {
        title: "작성일",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (createdAt) => {
          const conveted = dateConvertor(new Date(createdAt));
          return <>{conveted}</>;
        },
      },
      {
        title: "관리",
        render: (item) => {
          const updateOnClick = () => {
            params.focus.setState(item);
            params.page.setState("noticeUpdate");
          };

          const deleteOnClick = async (close) => {
            const requestBody = {
              id: item.id,
              action: "delete",
            };
            const { data } = await axios.post(`${HOST}/admin/notice/crud`, requestBody, httpHeader());
            close();
            params.reset({ selected: 0, init: true });
          };

          return (
            <ButtonWrapper>
              <UpdateButton onClick={updateOnClick}>수정</UpdateButton>
              <Popup
                trigger={<DeleteButton>삭제</DeleteButton>}
                modal={true}
                contentStyle={{
                  padding: 25,
                  width: 300,
                  border: 0,
                  borderRadius: 10,
                }}
              >
                {(close) => (
                  <DeleteForm>
                    <DeleteLabel>정말 삭제하시겠습니까?</DeleteLabel>
                    <Button onClick={deleteOnClick.bind(null, close)} text="삭제" style={{ padding: "10px 15px" }} />
                  </DeleteForm>
                )}
              </Popup>
            </ButtonWrapper>
          );
        },
      },
    ],
  };
};

export default ColumnFactory;
