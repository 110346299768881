import React, { useContext } from "react";
import styled from "styled-components";
import { ClipLoader } from "react-spinners";

import Header from "./Header";
import Footer from "./Footer";
import globalContext from "../../Hooks/globalContext";
import Background from "../../Resources/Images/background.png";

const Outer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  background-image: url(${Background});
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  max-width: 450px;
  min-width: 320px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.35);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.35);
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  display: flex;
  background-color: white;
  align-items: center;
  flex-direction: column;
`;

const PageWrapper = ({ children, style = null, contentStyle = null }) => {
  const { user } = useContext(globalContext);
  return (
    <Outer>
      <Wrapper style={style}>
        <Header />
        <ContentWrapper style={contentStyle}>
          {user.state ? children : <ClipLoader size={15} color="white" />}
        </ContentWrapper>
        <Footer />
      </Wrapper>
    </Outer>
  );
};

export default PageWrapper;
