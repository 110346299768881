import React from "react";
import styled from "styled-components";

import { useHistory } from "react-router-dom";

const NameMark = styled.img`
  height: 20px;
  width: auto;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const PageName = styled.div`
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const Header = () => {
  const history = useHistory();

  return (
    <HeaderWrapper>
      <PageName>
        {history.location.pathname === "/" ? "HOME" : history.location.pathname.split("/")[1].toUpperCase()}
      </PageName>
    </HeaderWrapper>
  );
};

export default Header;
