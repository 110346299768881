import React, { useContext } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

import GlobalContext from "../Hooks/globalContext";
import Main from "../Pages/Main";
import AuthInvite from "../Pages/AuthInvite";
import Config from "../Pages/Config";
import Language from "../Pages/Config/Language";
import Exchange from "../Pages/Config/Exchange";
import Send from "../Pages/Send";
import History from "../Pages/History";
import Staking from "../Pages/Staking";
import Swap from "../Pages/Swap";
import NoticeDetail from "../Pages/Notice/Detail";
import Notice from "../Pages/Notice";

import Header from "./Common/Header";
import Footer from "./Common/Footer";

import Admin from "../Pages/Admin";
import Auth from "../Pages/Auth";

const LoginRoutes = ({ user }) => {
  const history = useHistory();
  return (
    <Switch>
      {/* <Route exact path="/" component={Main} />
      <Route exact path="/more" component={Config} />
      <Route exact path="/language" component={Language} />
      <Route exact path="/exchange" component={Exchange} />
      <Route exact path="/send" component={Send} />
      <Route exact path="/send/:asset" component={Send} />
      <Route exact path="/history" component={History} /> */}
      {/* <Route exact path="/staking" component={Staking} /> */}
      {/* <Route exact path="/notice/:noticeId" component={NoticeDetail} /> */}
      {/* <Route exact path="/notice" component={Notice} /> */}
      {/* <Route exact path="/swap" component={Swap} /> */}
      <Route exact path="/" component={Admin} />

      <Redirect from="*" to="/" />
    </Switch>
  );
};

const LogoutRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <Auth {...props} page="login" />} />
      <Route exact path="/login/verify" render={(props) => <Auth {...props} page="login/verify" />} />
      <Route exact path="/register/wallet" render={(props) => <Auth {...props} page="wallet" />} />
      <Route exact path="/register/invite/:code" component={AuthInvite} />
      <Route exact path="/register" render={(props) => <Auth {...props} page="register" />} />
      <Route exact path="/register/:recommend" render={(props) => <Auth {...props} page="register" />} />
      <Route exact path="/password/forgot" render={(props) => <Auth {...props} page="forgot" />} />
      <Route exact path="/password/verify" render={(props) => <Auth {...props} page="verify" />} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

const AppRouter = () => {
  const { isLogin, user } = useContext(GlobalContext);
  return isLogin.state ? <LoginRoutes user={user} /> : <LogoutRoutes />;
};

export default AppRouter;
