import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import { LanguageIcon, CheckedIcon } from "../../../Components/Icons";
import { languageAction, languagePack } from "../../../Utils/language";
import globalContext from "../../../Hooks/globalContext";
import PageWrapper from "../../../Components/Common/PageWrapper";
import GoBack from "../../../Components/Common/GoBack";
import useState from "../../../Hooks/useState";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 0px 20px;cursor: pointer;
  :hover {
      background-color: ${(props) => props.theme.lightGrey};
      opacity: 0.75;
  }
  :not(:last-child) {
    margin-bottom: 5px;
  }
 
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  font-size: 14px;
  align-items: center;
`;

const ItemLabel = styled.span`
  color: black;
  margin-left: 10px;
`;

const MenuItem = ({ language, title, icon, onClick, checked }) => {
  return (
    <Row onClick={onClick}>
      <Item>
        {icon}
        <ItemLabel>{title}</ItemLabel>
      </Item>
      <Item>
        {language === checked ?
          <CheckedIcon />
          : <></>
        }
      </Item>
    </Row>
  );
};

const Page = ({ history }) => {
  const { language, user, setLanguage } = useContext(globalContext);

  const checked = useState(language.state)

  const onClick = (lang) => {
    checked.setState(lang)
    setLanguage({ target: { value: lang } })
  }

  return (
    <PageWrapper contentStyle={{ alignItems: "flex-start" }}>
      <GoBack onClick={() => history.goBack()} />
      <Wrapper>
        {user.state && (
          <>
            <MenuItem
              icon={<LanguageIcon color={"grey"} />}
              title={languageAction("한국어", language.state)}
              language={'korean'}
              checked={checked.state}
              onClick={onClick.bind(null, "korean")}
            />
            <MenuItem
              icon={<LanguageIcon color={"grey"} />}
              title={languageAction("영어", language.state)}
              language={'english'}
              checked={checked.state}
              onClick={onClick.bind(null, "english")}
            />
          </>
        )}
      </Wrapper>
    </PageWrapper>
  );
};
export default Page;
