import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 200px;
`;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
};

const PTag = styled.p`
  @media (min-width: 401px) {
    font-size: 13px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
  margin: 0;
`;

export default ({ single, setSingle }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    maxSize: 1024 * 1024 * 20,
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          const reader = new FileReader();
          reader.readAsBinaryString(file);
          reader.onload = (e) => {
            setSingle({ name: file.name, binary: e.target.result });
          };
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });

  const thumbs = files.map((file) => <div key={file.name}> {file.name}</div>);
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const ImageBox = (
    <section className="container" style={{ padding: 0 }}>
      <Container {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <PTag>엑셀파일을 눌러서 업로드하세요.</PTag>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </Container>
    </section>
  );
  return ImageBox;
};
